// Custom Validation Rules

jQuery.validator.addMethod('atleast1letter1digit', function(value, element) {
    return this.optional(element) || (/[a-z]/i.test(value) && /\d/.test(value));
}, 'Please enter at least one letter and one digit');

jQuery.validator.addMethod('notExpired', function (value, element) {
            if ( this.optional( element ) ) {
                return "dependency-mismatch";
            }

            var $form = $(element).parents('form');
            // Expiration Check
            var monthfield = $form.find("select[name='expiration_month']");
            var month = monthfield.val();
            var year = $form.find("select[name='expiration_year']").val();
            var currentDate = new Date();
            var thisyear = currentDate.getFullYear();
            if (thisyear > year || (thisyear == year && month < currentDate.getMonth())) {
                return false;
            }
            else {
                if (month < 10) {
                    month = '0' + month;
                }
                var expiration = month + year.slice(-2);
                $(element).val(expiration);
                return true;
            }
        }, 'This Credit Card has Expired');


/* KEEP ON THE BOTTOM */
/* AFTER ALL VALIDATION RULES ARE DEFINED */

// Load Queued Validation Items
window.tfl_validate = (function ($, validate) {
    // will be used to replace the global object
    var replacement = {
        push: function (items) {
            $(function () {
                $(items[0]).validate(items[1]);
            });
        }
    };
    // run any rules that have been pushed already
    if (validate && validate.length > 0) {
        $.each(validate, function (idx, items) {
            replacement.push(items);
        });
    }
    return replacement;
})(jQuery, window.tfl_validate);
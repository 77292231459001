/* global TFL, $, MediaElementPlayer, tfl_validate, mejs, grecaptcha */
import NProgress from "./lib/nprogress";
import "./lib/jquery-ui-1.12.1.custom";
import "./lib/jquery.pjax";
import "./lib/jquery.scrollTo.min";
import "./jquery.validate.min";
import "./lib/jquery.validate.custom-methods";
import "./jquery.cookie";
window.TFL = window.TFL || {};
TFL.scrollPlayer = null;
TFL.mainPlayer = null;
TFL.cloudSync = true;
TFL.syncThrottle = false;
TFL.notification = false;
TFL.sync_data = {};
TFL.myFavorites = false;

const SITE_WRAPPER = "#site-wrapper";
const SCROLLPLAYER = "#scroll-player";
const MEDIA_SRC = "https://tflmedia-new.s3.amazonaws.com/";
const DOWNLOAD_SRC = "https://downloads.truthforlife.org/";
const PROGRESS_COOKIE = "playerprogress";
const SYNC_THROTTLE = 30000;
const LISTENQUEUE = ".js-up-next";

function RunApp() {
  // Form Validation Configuration
  setupFormValidation();
  setupChangeBoxes();

  // Navigation and Datepicker
  setupPrimaryDatepicker();
  offCanvasNavigation();
  setupMobileNavigation();
  sidebarToggle();
  filterbarInteractions();
  affixDayBrowser();
  setupPjax();
  closeSidebarOnModalOpen();
  setupDatepickerArrowTab()

  // Site Notifications
  dismissNotificationBox();
  setupActiveNav();

  // Program Series Handling
  seriesLoadView();
  seriesToggle();

  // Accounts and Login
  setupTransactionDetail();
  setupTransactionForm();
  disconnectSocialAccounts();
  toggleLoginRegistration();
  disconnectSocialAccountsPasswordValidation();
  setupProfileForm();
  setupFirstNameWarning();

  // Site Search
  setupSiteSearch();

  // Daily Page
  setupBibleReadingToggle();
  setupDailyTabShare();

  // TFL Store / Products
  productOptionSelect();
  toggleNotification();
  declineDonationProduct()
  givingAmounts();
  updateAddToCart();
  showDigitalGiftWarning();
  storeOrderSubmit();

  // Media Player
  setupMediaTypeToggle();
  setupMiniPlayerToggles();

  // Initialize Items
  initializeMedia();
  setAudioAsDefault();

  // Favorites
  setupFavoriteToggle();

  // Section Show/Hide Toggle & Account Prefs
  setupHomePageSectionToggle();
  setupToggleShowHideAccountPref();
  setupToggleRememberMePref();

  // Downloads
  setupDownloadLogging();

  // Listen Queue
  setupListenQueue();

  // Learning Tracks
  setupLearningTracks();
  markSermonAsComplete();

  // Sliders
  createSliders();

  // Misc Functionality
  smoothScrolling();
  footnoteSmoothScrolling();
  setupTooltips();
  setupShowOnLoad();

  // Homepage hubspot form
  createHubSpotForm();

  // Look Inside
  setupLookInside();
}

$(function() {
  RunApp();
});

// Pjax Related Grouping
function ResetApp() {
  setupTooltips();
  setupShowOnLoad();
  setupPrimaryDatepicker();
  disconnectSocialAccounts();

  // Media Player items
  initializeMedia();
  updateMediaNextButtonStatus();

  // Sliders
  createSliders();

  // Hubspot Form
  createHubSpotForm();
}

// FUNCTION DEFINITIONS

// ----------------------------------------------------  Utility Functionality

function smoothScrolling() {
  $(SITE_WRAPPER).on("click", ".smooth", function(event) {
    event.preventDefault();
    $("html,body").animate({ scrollTop: $(this.hash).offset().top - 80 }, 500);
  });
}

function footnoteSmoothScrolling() {
  $(SITE_WRAPPER).on("click", ".ab_footnote", function(ev) {
    ev.preventDefault();
    $('html, body').animate({
        scrollTop: $('[name="' + $.attr(this, 'href').substr(1) + '"]').offset().top - 80
    }, 500);

    return false;
  });
}

function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function setupTooltips() {
  $('[data-toggle="tooltip"]').tooltip();
  $(".requires-tooltip").tooltip({ trigger: "hover" });
  $('[data-toggle="popover"]').popover();

  // Hide tooltip if a popover is showing.
  $('.app-add-queue-popover').on('shown.bs.popover', function() {
    $(".tooltip").tooltip('hide');
  });
}

// Show element for 10s then remove forced opacity.
function setupShowOnLoad() {
    setTimeout(function() {
      $(".js-showonload").removeClass("js-showonload");
    }, 8000);
}

function getCleanUrl(href) {
  var hash = href.indexOf("#");
  return href.substr(0, hash != -1 ? hash : href.length);
}

function isIE() {
  if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    navigator.appVersion.indexOf("Trident/") > -1
  ) {
    return true; /* Microsoft Internet Explorer detected in. */
  }
  return false;
}

// ----------------------------------------------------  END Misc Functionality

// ----------------------------------------------------  Look Inside

function setupLookInside(){
  $(SITE_WRAPPER).on("click", ".look-inside_launch", function(event){

    var jqButton    = $(event.currentTarget)
    var jqContainer = jqButton.parent();
    var jqModal     = jqContainer.find(".look-inside_window");
    var jqImages    = jqModal.find("img[data-defer]");
    var jqNudge     = jqModal.find(".look-inside_scroll");
    var jqCollapse  = jqModal.find(".collapse");

    // when the modal closes
    var handleClose = function(){
      // teardown
      jqCollapse.collapse("hide");
      jqNudge.off("click", handleNudge);
      jqModal.off("hide.bs.modal", handleClose);
      jqModal.off("scroll", handleScroll);
      $("html, body").css({overflow:"hidden auto"});
    };
    // when the modal scrolls (used to hide the scroll-indicating down arrow button at the bottom after scrolling )
    var handleScroll = function(){
      if(jqModal.scrollTop() > 200){
        jqModal.addClass("look-inside--scrolled");
        jqModal.off("scroll", handleNudge);
      }
    };
    // when the scroll-indicating down arrow button is clicked
    var handleNudge = function(){
      jqModal.animate({scrollTop:600}, 500);
    };

    // setup (teardown in handleClose)
    jqNudge.on("click", handleNudge);
    jqModal.on("hide.bs.modal", handleClose);
    jqModal.on("scroll", handleScroll);
    $("html, body").css({overflow:"hidden hidden"});
    jqModal.removeClass("look-inside--scrolled");

    // defer load images
    jqImages.each(function(inIndex, inItem){ inItem.setAttribute("src", inItem.getAttribute("data-defer")); });

    // open modal
    jqModal.modal("toggle");

  });
}

// ----------------------------------------------------  END Look Inside

// ----------------------------------------------------  Form Validation Configuration

function setupFormValidation() {
  // Validate email subscribe form and post
  tfl_validate.push([
    "#email_subscribe_form",
    {
      rules: {
        firstname: "required",
        lastname: "required",
        frequency: "required",
        email: {
          required: true,
          email: true
        }
      },
      messages: {
        firstname: "Please enter your first name.",
        lastname: "Please enter your last name.",
        email: "Please enter a valid email address.",
        frequency: "Check at least one list option."
      },
      //perform an AJAX post
      submitHandler: function(formobj) {
        $("#button_subscribe_form").hide();
        $("#loader_subscribe_form").show();
        $.post(formobj.action, $("#email_subscribe_form").serialize()).done(
          function(data) {
            $("#email_subscription_wrapper")
              .empty()
              .append(data);
          }
        );
      }
    }
  ]);

  // Validate use login form and post
  tfl_validate.push([
    "#login_form",
    {
      rules: {
        password: "required",
        username: {
          required: true,
          email: true
        }
      },
      messages: {
        password: "Please enter your password.",
        username: "Please enter your email address."
      },
      //perform an AJAX post
      submitHandler: function(formobj) {
        $("#button_login_form").hide();
        $("#loader_login_form").show();
        $.post(formobj.action, $("#login_form").serialize()).done(function(
          data
        ) {
          let json = $.parseJSON(data);
          if (json["return"] == "True") {
            location.reload(true);
          } else {
            $("#loader_login_form").hide();
            $("#button_login_form").show();
            $("#login_form_wrapper").prepend(
              "<span id='login-error'>Please check your login again.</span>"
            );
          }
        });
      }
    }
  ]);

  // Validate use login form and post
  tfl_validate.push([
    "#modal_login_form",
    {
      rules: {
        password: "required",
        username: {
          required: true,
          email: true
        }
      },
      messages: {
        password: "Please enter your password.",
        username: "Please enter your email address."
      },
      //perform an AJAX post
      submitHandler: function(formobj) {
        $("#button_modal_login_form").hide();
        $("#loader_modal_login_form").show();
        $.post(formobj.action, $("#modal_login_form").serialize()).done(
          function(data) {
            let json = $.parseJSON(data);
            if (json["return"] == "True") {
              location.reload(true);
            } else {
              $("#loader_modal_login_form").hide();
              $("#button_modal_login_form").show();
              $("#modal_login_body").prepend(
                "<span id='login-error'>Please check your login again.</span>"
              );
            }
          }
        );
      }
    }
  ]);

  // Validate One-Year Bible Reading subscribe form
  tfl_validate.push([
    "#daily-devo-subscription",
    {
      rules: {
        firstname: "required",
        lastname: "required",
        frequency: "required",
        email: {
          required: true,
          email: true
        }
      },
      messages: {
        firstname: "First name required.",
        lastname: "Last name required.",
        email: "Valid email address required.",
        frequency: "Check at least one list option."
      }
    }
  ]);

    // Validate registration form
  tfl_validate.push([
    "#registration_form",
    {
      submitHandler: function (form) {
        $("#registration_submit").prop("disabled", true);
        form.submit();
      }
    }
  ]);
}

function setupChangeBoxes() {
  $("body").on("click", ".js-change_box", function() {
    var link = $(this).find(".js-change_link");
    if (link.length) {
      var show = $(link).data("show");
      var hide = $(link).data("hide");
      var field = $(link).data("field");

      $("." + show).fadeIn();
      $("." + hide).remove();
      if (field) {
        $("." + field).val("yes");
      }
    }
  });
}

// ----------------------------------------------------  END Form Validation Configuration

// ----------------------------------------------------  Navigation and Datepicker

function setupPrimaryDatepicker() {
  var datePicker = $("#date-picker");
  if (datePicker.length > 0) {
    datePicker.datepicker({
      dateFormat: "MM d, yy",
      onSelect: function(date, datepicker) {

        var jqDatepicker = $(this);
        var urlPrefix = "/?date=";
        var queryParams = "";

        // Has the user selected today? Prepend "Today, " to the input.
        if ( $.datepicker.formatDate(datepicker.settings.dateFormat, new Date()) === date) {
          jqDatepicker.val("Today, " + jqDatepicker.val());
        }

        // on the resources page update the url not the query string
        if (jqDatepicker.hasClass("devotional")) {
          urlPrefix = "/devotionals/spurgeon/";
        } else if (jqDatepicker.hasClass("abdevotional")) {
          urlPrefix = "/devotionals/alistair-begg/";
        // daily page url is /daily/
        } else if (jqDatepicker.hasClass("daily")) {
          urlPrefix = "/daily" + urlPrefix;
          var activeTabId = $(".js-daily-tab.in.active").attr("id");
          if (activeTabId.length) {
            queryParams = "&tab="+activeTabId;
          }
        }
        changeLocation(urlPrefix + (datepicker.selectedMonth + 1) + "/" + datepicker.selectedDay + "/" + datepicker.selectedYear + queryParams);
      }
    });

    if (datePicker.data("datepicker-options")) {
      datePicker.datepicker("option", datePicker.data("datepicker-options"));
    }

    if (
      datePicker.val().match(/^Today,/) === null &&
      datePicker.datepicker("getDate") &&
      datePicker.datepicker("getDate").toDateString() ===
        new Date().toDateString()
    ) {
      datePicker.val("Today, " + datePicker.val());
    }
  }
}

function setupDatepickerArrowTab() {
  $(document).on("click", ".js-daily-date-arrow", function(e) {
    e.preventDefault();
    var newUrl = $(e.currentTarget).attr('href');
    var activeTabId = $(".js-daily-tab.in.active").attr("id");
    if (activeTabId.length) {
      newUrl += (newUrl.indexOf('?' !== -1) ? '&' : '?') +  "tab=" + activeTabId;
    }
    changeLocation(newUrl);
  })
}

function changeLocation(url) {
  var container_id = SITE_WRAPPER;
  var container = $(container_id);
  if (
    $.support.pjax &&
    container.length > 0 &&
    $("body").hasClass("player-open")
  ) {
    $.pjax({
      url: url,
      container: container_id
    });

    // WEBKIT NOTE:
    //
    // Old webkit browsers have trouble with position: fixed + transform. This
    // is our attempt to disable NProgress those older webkit versions. For
    // webkit browsers that don't have websockets support we don't enable NProgress.
    if (
      $("html").hasClass("websockets") ||
      !("WebkitTransform" in document.body.style)
    ) {
      NProgress.configure({ parent: "body" });
      NProgress.start();
    }
  } else {
    window.location = url;
  }
}

function offCanvasNavigation() {
  $(SITE_WRAPPER).on("click", ".toggle-nav", function(event) {
    // Calling a function in case you want to expand upon this.
    toggleNav(event);
  });
}

function toggleNav(event) {
  event.preventDefault();
  if ($(SITE_WRAPPER).hasClass("show-nav")) {
    // Do things on Nav Close
    $(SITE_WRAPPER).removeClass("show-nav");
    affixDayBrowser();
    $("body").removeClass("nav-open");
  } else {
    // Do things on Nav Open
    $(SITE_WRAPPER).addClass("show-nav");
    unaffixDayBrowser();
    $("body").addClass("nav-open");

    // focus on Search field when opening
    setTimeout(function() {
      $("#searchCriteria").focus();
    }, 0);

    // Remove focus from all players to disable keyboard shortcuts.
    // It appears the clicks on Bootstrap dropdown elements don't propogate up,
    // so the listener in the mediaelement code doesn't get triggered to remove
    // the focuse of the player.
    if (mejs !== "undefined" && mejs.players) {
      for (let playerIndex in mejs.players) {
        mejs.players[playerIndex].hasFocus = false;
      }
    }
  }
}

// Affix day browser to top of screen when scrolled past.
function affixDayBrowser() {
  // We need to dynamically calculate the top offset
  var nav = $(".nav-outer-wrapper");
  $(".day-browser-outer-wrapper")
    .affix({
      offset: {
        top: function() {
          return nav.outerHeight();
        }
      }
    })
    // toggle class on nav element to add extra margin when affixed
    // this prevents a jump in the content when the day browser is affixed.
    .on("affixed.bs.affix", function() {
      nav.addClass("day-browser-affixed");
    })
    .on("affixed-top.bs.affix", function() {
      nav.removeClass("day-browser-affixed");
    });
}

function unaffixDayBrowser() {
  $(window).off(".affix");
  $(".day-browser-outer-wrapper")
    .removeData("bs.affix")
    .removeClass("affix affix-top affix-bottom");
}

function sidebarToggle() {
  $(SITE_WRAPPER).on("click", ".js-sidebar-toggle", function(ev) {
    $(ev.target).closest(".js-sidebar-wrapper").toggleClass("open");
  });
}

function setupMobileNavigation() {
  $(SITE_WRAPPER).on("click", ".visible-titles", function() {
    $("#subnav-items").slideToggle("fast");
  });

  // when mobile nav links or link-tabs get clicked,
  // send click event to the primary tab with the same href as this one
  $(SITE_WRAPPER).on("click", ".mobile-nav a, .link-tabs a", function(e) {
    e.preventDefault();
    $('a[data-toggle="tab"]')
      .filter('[href="' + $(this).attr("href") + '"]')
      .click();
  });
}

function filterbarInteractions() {
  $(SITE_WRAPPER).on("click", ".nav-filterbar-list li", function(event) {
    event.preventDefault();

    var theid = $(this)
      .children("span:first")
      .attr("data-src");
    // First close any secondary filters currently open:
    $(".filterbar-options.second").slideUp();

    // Then open the filter requested:
    $("#" + theid).slideDown();
    $(".filterbar-collaps").show();
    $(".nav-filterbar-list li").removeClass("active");
    $(this).addClass("active");
  });

  $(SITE_WRAPPER).on(
    "change",
    ".nav-filterbar .filterbar-options.first select",
    function() {
      var selectid = $(this).val();

      // First close any secondary select filters currently open:
      $(".filterbar-options.second").slideUp();

      // Then open the filter requested:
      $("#" + selectid).slideDown();
    }
  );

  $(SITE_WRAPPER).on("click", ".filterbar-options-list li", function(event) {
    if ($(this).hasClass("makelink")) {
      return;
    }
    event.preventDefault();
    var thecriteriaid = $(this)
      .children("span:first")
      .attr("data-src");
    // First close any tertiary filters that are open:
    $(".criteria-support").slideUp();

    // Then open the one requested:
    $("#" + thecriteriaid).slideDown();
    $(".filterbar-options-list li").removeClass("active");
    $(this).addClass("active");
  });

  $(SITE_WRAPPER).on("change", ".filterbar-options.second select", function() {
    if ($(this).hasClass("makelink")) {
      return;
    }
    var selectcriteriaid = $(this).val();
    // First close any tertiary select filters currently open:
    $(".criteria-support").slideUp();

    // Then open the filter requested:
    $("#" + selectcriteriaid).slideDown();
  });

  $(SITE_WRAPPER).on("click", ".filterbar-collaps", function(event) {
    event.preventDefault();
    $(".filterbar-options").slideUp("fast", function() {
      $(".filterbar-collaps").hide();
      $(".nav-filterbar-list li").removeClass("active");
      $(".filterbar-options-list li").removeClass("active");
      $(".criteria-support").hide();
    });
  });
}

function setupPjax() {
  var container_id = SITE_WRAPPER;
  var container = $(container_id);
  var bodyOldClass = "";
  var currentUrl = getCleanUrl(window.location.href);

  if ($.support.pjax && container.length > 0) {
    $.pjax.defaults.timeout = 0;
    $.pjax.defaults.maxCacheLength = 0; // prevents back button media missing error

    $(document).on("submit", "form[data-pjax]", function(event) {
      if (!$("body").hasClass("player-open") && !hasMainPlayer()) {
        return;
      } else {
        currentUrl = getCleanUrl(window.location.href);
      }
      $.pjax.submit(event, container_id);
    });

    $(document).on("click", "a", function(event) {
      // Check if the page supports the media player
      if ($(this).hasClass("noplayer")) {
        if ($("body").hasClass("player-open") || hasMainPlayer()) {
          $(this).attr({target: "_blank", rel: "noopener"});
          return;
        }
      }

      // Open any link that ends with /donate or /donate/ in new tab if player open.
      let href = $(this).attr("href");
      // If no href, then just stop.
      if (!href) {
        return;
      }

      if ($(this).hasClass("js-force-pjax")) {
        // Forced Pjax links always load via pjax
        currentUrl = getCleanUrl(window.location.href);
      }
      else {
        if (
          href?.match(/\/donate\/?$/i) ||
          href?.match(/\/donate\/truthpartner\/?$/i) ||
          href?.match(/\/become-a-truthpartner\/?$/i)
        ) {
          if ($("body").hasClass("player-open") || hasMainPlayer()) {
            $(this).attr({target: "_blank", rel: "noopener"});
            return;
          }
        }

        if (!$("body").hasClass("player-open") && !hasMainPlayer()) {
          return;
        }
        else {
          currentUrl = getCleanUrl(window.location.href);
        }
        if ($(this).data("pjax") === false) {
          return;
        }

        if (
          $(this)
            .attr("href")
            ?.match(/\.(pdf|zip)$/)
        ) {
          $(this).attr({target: "_blank", rel: "noopener"});
          return;
        }
      }
      $.pjax.click(event, {
        url: $(this).attr("href"),
        container: container_id
      });
    });

    container.on("pjax:start", function() {
      // if main player media is active, pin it.
      if (hasMainPlayer()) {
        var miniButton = $(TFL.mainPlayer).find(".app-miniplayer-button");
        if (miniButton.length) {
          $(miniButton)
            .first()
            .click();
          if (!$(TFL.mainPlayer).attr("data-origin-page")) {
            $("#scroll-player-origin").attr("href", currentUrl);
          }
        }
      }

      // See WEBKIT NOTE above.
      if (
        $("html").hasClass("websockets") ||
        !("WebkitTransform" in document.body.style)
      ) {
        NProgress.configure({ parent: "body" });
        NProgress.start();
      }

      // Close mobile navigation if it is open.
      if ($(SITE_WRAPPER).hasClass("show-nav")) {
        $(".toggle-nav").click();
      }

      // Close any Boostrap modals
      $(".modal:visible").modal("hide");
      $(".modal-backdrop").remove();

      bodyOldClass = $("body").attr("class");
    });

    container.on("pjax:end", function() {
      var bodyNewClass = $("body").attr("class");
      if (bodyNewClass && bodyOldClass) {
        $("body").attr("class", bodyNewClass.replace(bodyOldClass, ""));
      }

      // See WEBKIT NOTE above.
      if (
        $("html").hasClass("websockets") ||
        !("WebkitTransform" in document.body.style)
      ) {
        NProgress.done();
      }
      $(SITE_WRAPPER).removeClass("show-nav");
      // Deal with the body classes. Set them based on the input#page_classes.

      // Reset only the things we need to attach on this page load
      ResetApp();

      // Handle ScrollPlayer
      if (hasMiniPlayer()) {
        $("body").addClass("player-open");
        if (TFL.scrollPlayer.options.defaultplayer === true) {
          returnToDefaultPlayer(false);
        } else {
          setAudioAsDefault();
        }
      }
    });
  }
}

function closeSidebarOnModalOpen() {
  $('[data-toggle="modal"]').on("click", function() {
    $(SITE_WRAPPER).removeClass("show-nav");
    $("body").removeClass("nav-open");
  });
}

// ----------------------------------------------------  END Navigation and Datepicker

// ----------------------------------------------------  Program Series Handling

function seriesLoadView() {
  // Show load bar when changing the series volume to view.
  $('.series-title-wrapper [data-toggle="tab"]').on("show.bs.tab", function(e) {
    NProgress.configure({ parent: ".current-series-outer-wrapper" });
    var target = $(e.target);
    if (target.hasClass("active")) {
      // Already active so don't do anything.
      return;
    }
    var parentli = target.closest("li");
    parentli
      .siblings("li.active")
      .not(parent)
      .removeClass("active");
    var series_number = target
      .closest(".btn-group.volumes")
      .find("button .active-series-number");
    series_number.text(target.text());
    var pane = $(target.attr("data-target"));
    if ($.trim(pane.html()) === "") {
      // Need to fetch the data for this pane.

      // See WEBKIT NOTE above.
      if (
        $("html").hasClass("websockets") ||
        !("WebkitTransform" in document.body.style)
      ) {
        NProgress.start();
      }
      pane.load(pane.data("src"), function() {
        // See WEBKIT NOTE above.
        if (
          $("html").hasClass("websockets") ||
          !("WebkitTransform" in document.body.style)
        ) {
          NProgress.done();
        }
      });
    } else {
      // See WEBKIT NOTE above.
      if (
        $("html").hasClass("websockets") ||
        !("WebkitTransform" in document.body.style)
      ) {
        NProgress.start();
        NProgress.done();
      }
    }
  });
}


function seriesToggle() {
  $(SITE_WRAPPER).on("click", ".current-series-toggle-wrapper a", function() {
    $(".current-series-outer-wrapper").slideToggle("fast");
    $("body").toggleClass("openSeries");
  });
}


// ----------------------------------------------------  End Program Series Handling

// ----------------------------------------------------  Site Notifications

// Allow dismissing Notification Boxes
function dismissNotificationBox() {
  $("body").on("click", ".close-notification", function() {
    var notification = $(this).parent(".alert-notification");
    if (notification.attr("id")) {
      var cookie_id = notification.attr("id");
      $.cookie(cookie_id, "1", { expires: 90, path: "/", secure: true });
      $("#" + cookie_id).hide();
    }
  });
}

function setupActiveNav() {
  var activeClasses = [
    ".index .primary-today",
    ".resources .primary-resources",
    ".resources_sermons_index .secondary-sermon-archive",
    ".resources_sermons_single-player .secondary-sermon-archive",
    ".resources_topics_archive .secondary-topics-archive",
    ".resources_sermons_scriptures .secondary-sermons-scriptures",
    ".resources_sermons_series .secondary-series-archive",
    ".resources_series_single-player .secondary-series-archive",
    ".resources_devotionals_index .secondary-devotional-archive"
  ];
  $("body").ready(function() {
    $.each(activeClasses, function(i, elem) {
      $(elem).addClass("active");
    });
  });
}

// ----------------------------------------------------  END Site Notifications

// ----------------------------------------------------  Accounts and Logins

function setupFirstNameWarning() {
  $(document).on("keyup", "#id_first_name", function(ev) {
    var regex = new RegExp(/( and |&)/i);
    var $input = $(ev.currentTarget);
    var $warning = $("#id_first_name-warning");
    if ($input.val().search(regex) > 0 && $warning.length > 0) {
      var name_str = $input.val();
      $input.val(name_str.substring(0, name_str.search(regex)));
      $warning.css({ display: "block", visibility: "visible" });
      var message_timer = $warning.data("message-timer") ?? 5000;
      setTimeout(function() {
        $warning.fadeOut(function() {
          $warning.css({ display: "none", visibility: "hidden" });
        });
      }, message_timer);
    }
  });
}

function setupProfileForm() {
  var formSelector = ".account-profile-form";
  var submitButtonSelector = formSelector + ' button[type="submit"]';

  function enableSubmitButtonsOnChange() {
    var inputSelectors = [
      "select",
      'input[type="checkbox"]',
      'input[type="radio"]',
      'input[type="date"]',
      'input[name="radio_station_umedid"]'
    ];
    var changeSelector = inputSelectors.map(function(selector) {
      return formSelector + " " + selector;
    });
    $(document).on("change", changeSelector.join(", "), function() {
      $(submitButtonSelector).prop("disabled", false);
    });
    $(document).on("input", formSelector + " input", function() {
      $(submitButtonSelector).prop("disabled", false);
    });
  }

  function disableSubmitButtonsOnSubmit() {
    $(document).on("click", submitButtonSelector, function() {
      $(submitButtonSelector).prop("disabled", true);
    });
  }

  enableSubmitButtonsOnChange();
  disableSubmitButtonsOnSubmit();
}

// Account Transaction Detail Setup
function setupTransactionDetail() {
  var selector = ".js-transaction-detail-link";
  var transactionList = $(".transaction-details-list");
  var transactionDetails = $(".transaction-details--success");
  var skeleton = $(".transaction-details--loading");
  var skelDate = skeleton.find(".transaction-overview__date");
  var skelId = skeleton.find(".transaction-overview__transaction-id-value");
  var skelTotal = skeleton.find(".summary__amount--total");
  function loadTransactionDetails(href) {
    transactionDetails.load(href + " .transaction-details", function(
      response,
      status
    ) {
      if (!skeleton.hasClass("hide") && status !== "error") {
        transactionDetails.removeClass("hide");
        skeleton.addClass("hide");
      }
    });
  }
  $(document).on("click", selector, function(event) {
    event.preventDefault();
    var link = $(event.currentTarget);
    var data = link.closest("tr").data("transaction");
    skelDate.text(data.date);
    skelId.text(data.id);
    skelTotal.text(data.amount);
    skeleton.removeClass("hide");
    transactionList.addClass("hide");
    loadTransactionDetails(link.attr("href"));
  });

  $(document).on("click", ".view-transactions-header", function(event) {
    if (transactionList.length === 0) {
      return;
    }
    event.preventDefault();
    transactionList.removeClass("hide");
    transactionDetails.addClass("hide");
    skeleton.addClass("hide");
  });
}

// Auto Submit Transaction Year End Preferences
function setupTransactionForm() {
  var form = "#js-transaction-prefs";
  var opt = ".js-transaction-pref-option";

  $(document).on("change", opt, function() {
    $.post($(form).attr("action"), $(form).serialize())
      .done(function(data) {
        let msg = data["message"];
        if (data["success"] == "success") {
          displaySuccessNotification(msg);
        } else {
          displayErrorNotification(msg);
        }
        $(opt).removeAttr("disabled");
      })
      .fail(function() {
        let msg =
          "There was an error saving your preference. Please try again later.";
        displayErrorNotification(msg);
        $(opt).removeAttr("disabled");
      });
    $(opt).attr("disabled", "disabled");
  });
}

// Disconnect social accounts.
function disconnectSocialAccounts() {
  $("#disconnect_confirm, #disconnect_new_password").on(
    "show.bs.modal",
    function(ev) {
      var modal = $(ev.currentTarget);
      var button = $(ev.relatedTarget);
      modal.find(".provider").text(button.data("provider-name"));
      modal.find("input[name=provider]").val(button.data("provider"));
    }
  );
}

function toggleLoginRegistration() {
  // Toggle Sign-in / Registration form elements
  $("body").on("click", ".account_tab_link", function() {
    var loc = $(this).attr("href");
    $('#account_form_tabs a[href="' + loc + '"]').tab("show");
  });
}

// Password validation on disconnect of all social accounts.
function disconnectSocialAccountsPasswordValidation() {
  var passwordRules = {
    rules: {
      new_password1: {
        required: true,
        minlength: 10,
        atleast1letter1digit: true
      },
      new_password2: {
        required: true,
        equalTo: "#disconnect_create_password_form #id_password"
      }
    },
    messages: {
      password: false,
      new_password2: {
        equalTo: "Password confirmation does not match original password."
      }
    },
    errorElement: "span",
    errorClass: "help-block validate-error",
    highlight: function(element) {
      $(element)
        .closest(".form-group")
        .addClass("has-error");
      $(element)
        .siblings(".form-control-feedback-success")
        .addClass("hide");
    },
    unhighlight: function(element) {
      $(element)
        .closest(".form-group")
        .removeClass("has-error");
      if ($(element).val() !== "") {
        $(element)
          .siblings(".form-control-feedback-success")
          .removeClass("hide");
      }
    },
    errorPlacement: function(error, element) {
      // Don't add error if message is blank.
      if (error.text() === "") {
        return;
      }
      error.appendTo(element.parent());
    },
    invalidHandler: function(form, validator) {
      if (validator.errorList.length === 0) {
        return;
      }
      validator.errorList[0].element.focus();
    },
    // Enable eager validation: http://stackoverflow.com/a/25797083/6765628
    onkeyup: function(element) {
      this.element(element);
    }
  };
  tfl_validate.push(["#disconnect_create_password_form", passwordRules]);
}

// ----------------------------------------------------  END Accounts and Logins

// ----------------------------------------------------  Site Search
function setupSiteSearch() {
  // when the desktop search icon is clicked...
  $("#dropdown-search").click(function() {
    var parent;
    parent = $(this).parent();

    // ...if the containing li is about to open...
    if (!parent.hasClass("open")) {
      // ...set input focus on the next DOM update (after it is visible; you cannot focus on hidden elements)
      setTimeout(function() {
        parent
          .find("input[type='text']")
          .eq(0)
          .focus();
      });
    }
  });

  // Setup Search Box Dropdown
  $(SITE_WRAPPER).on("click", ".dropdown-search", function(event) {
    // stop propagation so that dropdown doesn't close when it gets clicks.
    event.stopPropagation();
  });
}

// ----------------------------------------------------  END Site Search

// ----------------------------------------------------  Daily Page

function setupBibleReadingToggle() {
  $(document).on("click", ".js-reading-check", function(){ $(this).addClass("reading--check"); });
}

function setupDailyTabShare() {
  if(window.history)
  {
    // as the tabs change, update the browser URL in case someone wants to copy it
    $(document).on("click", ".js-daily-nav-link", function() {
      window.history.replaceState(null, "", $(this).attr("data-href"));
    });
  }
}

// ----------------------------------------------------  END Daily Page


// ----------------------------------------------------  TFL Store / Products

// inventory control. Set Add to Cart button based on availability
function updateAddToCart() {
  $(document).on("click", ".available-formats .format", function(ev) {
    var form = $(ev.currentTarget).closest("form");
    var format = form.find(".format.selected input");
    var btn = form.find(".js-addcart");
    if (format.hasClass("js-outofstock")) {
      btn.prop("disabled", true);
    }
    else {
      btn.prop("disabled", false);
    }
  });
  $('.available-formats .format.selected').click();
}


// On the donation and store product page we have buttons that need to
// mirror a radio input. On click we set radio button to checked and add
// selected class to format.
function productOptionSelect() {

  $(document).on("click", ".available-formats .format", function(ev) {
    var format = $(ev.currentTarget);
    format.find("input").prop("checked", true);
    format
      .closest(".available-formats")
      .find(".selected")
      .removeClass("selected");
    format.addClass("selected");
    updatePriceOrFairMarketValue();
    format.trigger("resourceSelected");
  });
  updatePriceOrFairMarketValue();

  // For the donation page, we want to update the fair market value.
  function updatePriceOrFairMarketValue() {
    $(".available-formats .format.selected").each(function(i, el) {
      var format = $(el);
      if (format.data("fair-market-value")) {
        // Donation page.
        format
          .closest(".form-group")
          .find(".fair-market-value")
          .text(format.data("fair-market-value"));
      } else if (format.data("price")) {
        // Product page.
        format
          .closest("form")
          .find(".discount-price .price-amount")
          .text(format.data("price"));
      }
    });
  }
}

// Toggle the store shipping notification
function toggleNotification() {
  $(".close-notice").click(function() {
    $(this)
      .parent()
      .fadeOut();
    $.cookie("shipping_notice", "true", { expires: 1, path: "/", secure: true });
    return false;
  });
}

function declineDonationProduct() {
  var update = function() {
    var inputChecked = document.querySelector(".js-donation-product input:checked");
    var inputWants = document.querySelector(".js-donation-product input[name='wants_donation_product']");

    if (inputWants && inputChecked) {
      inputWants.setAttribute("value", inputChecked.value == 'NO' ? 0 : 1);
    }
  };

  update();
  $(document).on("change", ".js-donation-product input", update);
}

function givingAmounts() {
  $(document).on("click", ".js-giving-amount", function(ev) {
    ev.preventDefault();
    var jqButton = $(ev.target);
    var jqInput = jqButton.parent().find(".js-giving-display");
    var amount = parseFloat(jqButton.attr("data-giving-amount"));
    jqInput.val(amount);
    jqInput.trigger("input");
  });
  $(document).on("input", ".js-giving-display", function(ev) {
    var jqInput = $(ev.target);
    var jqButton = jqInput.parent().find(".js-giving-amount");
    var amount = jqInput.val();
    jqButton.each(function(i){
      jqButton.eq(i).attr("data-active", parseFloat(jqButton.eq(i).attr("data-giving-amount")) == amount);
    });
  });
}

function showDigitalGiftWarning() {
  let warning = $("#digital-gift-warning");
  warning.hide();
  $(".js-donation-product").on("change", function(event) {
    if ($(event.target).attr("data-shippable") === "False") {
      warning.show();
    } else {
      warning.hide();
    }
  });
}

function storeOrderSubmit() {
  $(document).on("click", '#store_place_order_btn', function(ev) {
    ev.preventDefault();
    var form = $("#store_place_order_form");
    $("#store_place_order_btn").prop('disabled', true).addClass("disabled").text('Processing...');

    // Recaptcha Functionality
    grecaptcha.ready(function() {
      var recaptcha_id = form.find('.recaptcha_widget');
      var recaptcha = recaptcha_id.first();
      var sitekey = recaptcha.data('sitekey');
      grecaptcha.execute(sitekey, {action: 'store_checkout'}).then(function(token) {
        document.getElementById('g-recaptcha-response').value = token;
        form.submit();
      });
    });
  });
}


// ----------------------------------------------------  END TFL Store / Products

// ---------------------------------------------------- Learning Tracks
function recordLearningTracks(data) {
  var url = "/api/learningtracks/record/";
  var now = new Date().toISOString();
  data["completed_at"] = now;

  $.ajax({
    type: "POST",
    url: url,
    data: JSON.stringify({data: data }),
    contentType: "application/json"
  });
}

function removeLearningTracks(data) {
  var url = "/api/learningtracks/remove/";

  $.ajax({
    type: "POST",
    url: url,
    data: JSON.stringify({data: data }),
    contentType: "application/json"
  });
}


function setupLearningTracks() {
  $("body").on("click", ".js-reset-series-progress", function(ev) {
    ev.preventDefault();
    var node = $(ev.currentTarget);
    var url = "/api/learningtracks/reset/";
    var resource_id = $(node).attr("data-resource_id");
    if (resource_id) {
      $.ajax({
        type: "POST",
        url: url,
        data: JSON.stringify({
          data: {
            resource_id: resource_id,
          },
        }),
        contentType: "application/json"
      })
      .done(function() {
        displayInfoNotification(
          "Your progress has been restarted."
        );
        // All Set
        if (node.hasClass('js-force-pjax')) {
          forcePjaxReload();
        }
      })
      .fail(function() {
        displayErrorNotification(
          "Error Restarting Progress. Please Try Again."
        );
      });
    }
  });
}


function markSermonAsComplete() {
  // Complete
  $("body").on("click", ".app-completed-link", function(ev) {
    ev.preventDefault();
    var now = new Date().toISOString();
    var node = $(ev.currentTarget);

    if ($(node).attr("data-resource_id")) {
      var data = {
        'resource_id': $(node).attr("data-resource_id"),
        'resource_type': $(node).attr("data-resource_type"),
        'resource_format': 'audio',
        'completed_at': now,
      };

      mediaCompletionCloudSync(data);
      $(this).closest(".archive-support").find(".series-sermon-item").addClass("mark-complete");
      $("#js-sermon_count-current").text(parseInt($("#js-sermon_count-current").text()) + 1);
    }
  });

  // Not Complete
  $("body").on("click", ".app-not-completed-link", function(ev) {
    ev.preventDefault();
    var node = $(ev.currentTarget);

    if ($(node).attr("data-resource_id")) {
      var data = {
        'resource_id': $(node).attr("data-resource_id"),
        'resource_type': $(node).attr("data-resource_type"),
        'resource_format': 'audio',
      };

      removeLearningTracks(data);

      $(this).closest(".archive-support").find(".series-sermon-item").removeClass("mark-complete");
      $(this).closest(".archive-support").find(".list-player-progress-current").width(0)
      $("#js-sermon_count-current").text(parseInt($("#js-sermon_count-current").text()) - 1);
    }
  });
}

// ----------------------------------------------------  Media Player

// s is source, p is progress
function getMediaProgressFromCookie(src) {
  var myCookie = null;
  if ($.isFunction('cookie')) {
    myCookie = $.cookie(PROGRESS_COOKIE, null, {secure: true});
  }
  var progress = null;
  var progressArray = [];
  if (myCookie) {
    try {
      progressArray = JSON.parse(myCookie);
    } catch(e) {
      progressArray = [];
    }
    $.each(progressArray, function(index, value) {
      if (value["s"] == src) {
        progress = value["p"];
        return false;
      }
    });
  }
  return progress;
}

function mediaCompletionCloudSync(data) {
  recordLearningTracks(data);

  var url = "/api/playback/completion/sync/";
  return mediaProgressCloudSync(data, url);
}

function mediaProgressCloudSync(data, url) {
  url = url || "/api/playback/progress/sync/";
  var now = new Date().toISOString();
  data["updated_at"] = now;

  $.ajax({
    type: "POST",
    url: url,
    data: JSON.stringify({ last_sync: now, data: [data] }),
    contentType: "application/json"
  })
    .done(function() {
      // All Set
    })
    .fail(function(xhr) {
      if (xhr.status == "401") {
        TFL.cloudSync = false;
      }
    });
}

function mediaSyncThrottle() {
  TFL.syncThrottle = true;
  setTimeout(function() {
    TFL.syncThrottle = false;
  }, SYNC_THROTTLE);
}

function setMediaProgressThrottled(sync_data, src) {
  if (!TFL.syncThrottle) {
    mediaSyncThrottle();
    setMediaProgress(sync_data, src);
  }
}

function setMediaProgress(sync_data, src) {
  if (TFL.cloudSync) {
    // Set Cloud Sync Data
    mediaProgressCloudSync(sync_data);
    // Update Listen Queue with Change in Progress.
    setQueueProgressStatus(sync_data);
  } else {
    // Set Cookie
    var progressArray = [];
    // s == source (url)
    // p == progress (seconds float)
    var myProgress = { s: src, p: sync_data["seconds"] };
    var myCookie = $.cookie(PROGRESS_COOKIE, null, {secure: true});
    if (myCookie) {
      try {
        progressArray = JSON.parse(myCookie);
      } catch(e) {
        progressArray = [];
      }
      if (progressArray[0]) {
        if (progressArray[0]["s"] != src) {
          $.each(progressArray, function(index, value) {
            if (value && value["s"] == src) {
              progressArray.splice(index, 1);
              return false;
            }
          });
          progressArray.unshift(myProgress);
        } else {
          progressArray[0]["p"] = sync_data["seconds"];
        }
      } else {
        progressArray = [];
        progressArray.unshift(myProgress);
      }
    } else {
      progressArray.unshift(myProgress);
    }
    $.cookie(PROGRESS_COOKIE, JSON.stringify(progressArray.slice(0, 3)), {
      path: "/",
      expires: 365,
      secure: true,
    });
  }

}

function setMediaProgressTime(media, progress) {
  if (!isIE()) {
    // setCurrentTime crashes in IE11
    media.setCurrentTime(progress);
  } else {
    media.addEventListener(
      "loadedmetadata",
      function() {
        media.setCurrentTime(progress);
      },
      false
    );
  }
}


function getMediaProgress(sync_data, media) {
  var progress = null;
  TFL.cloudSync = true;

  if (sync_data["seconds"]) {
    progress = sync_data["seconds"];
  } else {
    progress = getMediaProgressFromCookie(media.src);
  }

  if (progress) {
    setMediaProgressTime(media, progress);
  }
}

function completeMediaProgress(sync_data, src) {
  if (TFL.cloudSync) {
    // Set Cloud Sync Data
    var now = new Date().toISOString();
    sync_data["completed_at"] = now;
    mediaCompletionCloudSync(sync_data);
  } else {
    // Set Cookie
    var progressArray = [];
    var myCookie = $.cookie(PROGRESS_COOKIE, null, {secure: true});
    if (myCookie) {
      try {
        progressArray = JSON.parse(myCookie);
      } catch(e) {
        progressArray = [];
      }
      $.each(progressArray, function(index, value) {
        if (value && value["s"] == src) {
          progressArray.splice(index, 1);
          $.cookie(PROGRESS_COOKIE, JSON.stringify(progressArray.slice(0, 3)), {
            path: "/",
            expires: 365,
            secure: true,
          });
          return false;
        }
      });
    }
  }
}

function createMediaElementPlayer(className, miniplayer) {
  $(className).mediaelementplayer({
    alwaysShowControls: "true",
    features: [
      "listenqueue",
      "skiptoprev",
      "playpause",
      "skiptonext",
      "current",
      "progress",
      "duration",
      "volume",
      "miniplayer",
      "fullscreen",
      "defaultplayer",
      "closeminiplayer"
    ],
    classPrefix: "mejs-",
    pluginPath: "/static/js/responsive/lib/mediaelement/",
    showPosterWhenEnded: true,
    setDimensions: true,
    enableAutosize: true,
    stretching: "responsive",
    audioVolume: "vertical",
    timerRate: 250,
    autoplay: miniplayer ? true : false,
    preload: miniplayer ? true : false,
    success: function(media, node, player) {
      TFL.sync_data[media.src] = {
        resource_type: $(node).attr("data-resource_type"),
        resource_id: $(node).attr("data-resource_id"),
        resource_format: $(node).attr("data-resource_format"),
        seconds: $(node).attr("data-progress"),
      };
      // Async setting of current progress
      getMediaProgress(TFL.sync_data[media.src], media);

      if (isIE()) {
        // Fixes IE video SVG Poster bug
        $(".video-container .mejs-poster").css({
          "background-size": 0
        });
      }

      media.addEventListener("timeupdate", function() {
        var progress = media.currentTime;
        if (progress > 0) {
          TFL.sync_data[media.src]["seconds"] = progress;
          setQueuePlayerTime(progress);
          setMediaProgressThrottled(TFL.sync_data[media.src], media.src);
        }
      });

      media.addEventListener("ended", function() {
        completeMediaProgress(TFL.sync_data[media.src], media.src);
        // PlayMiniPlayerNextItem
        playMiniPlayerNextItem(node, player);
      });

      media.addEventListener("playing", function() {

        // Queue Player Controls
        setQueuePlayerPlaying();
        setupQueuePlayerControls(media, player, node);
        showPlayingOverlay(player.container);
        var progress = media.currentTime;
        if (progress > 0) {
          TFL.sync_data[media.src]["seconds"] = progress;
        }

        var video = player.isVideo;
        var response = listenQueueItemAPIResponse(
          TFL.sync_data[media.src]["resource_id"],
          TFL.sync_data[media.src]["resource_type"],
          video
        );
        return response.then(queueData => {
          queueData.duration = media.duration;
          queueData.playerId = $(node).attr("id");

          // Used to track element for removal or reordering
          var uniqueId = `${queueData.resource_id}_${Date.now()}`;
          queueData.tracking_id = uniqueId;
          setNowPlaying(queueData);
        });
      });

      node.addEventListener("error", function() {
        // Most likely issue is a Src unable to play.
        // Show notification
        var info = getNowPlayingData();
        var msg = 'Unable to play "' + info.title + '"';
        displayErrorNotification(msg);
        playMiniPlayerNextItem(node, player);
      });

      player.container.addEventListener("enteredfullscreen", function() {
        var fs_btn = $(node).closest('.media-player').find(".mejs-fullscreen-button");
        if (fs_btn.length) {
          $(fs_btn).find('button').attr('title', 'Exit Fullscreen').attr('aria-label', 'Exit Fullscreen');
        }
      });

      player.container.addEventListener("exitedfullscreen", function() {
        var fs_btn = $(node).closest('.media-player').find(".mejs-fullscreen-button");
        if (fs_btn.length) {
          $(fs_btn).find('button').attr('title', 'Fullscreen').attr('aria-label', 'Fullscreen');
        }
      });

      media.addEventListener("pause", function() {
        setQueuePlayerPaused();
        showPausedOverlay(player.container);
        var progress = media.currentTime;
        if (progress > 0) {
          TFL.sync_data[media.src]["seconds"] = progress;
        }
        setMediaProgress(TFL.sync_data[media.src], media.src);
      });
      var screen_change_events =
        "webkitfullscreenchange mozfullscreenchange fullscreenchange MSFullscreenChange";
      $(document).on(screen_change_events, function() {
        fixVideoSize();
      });

      if (miniplayer) {
        spawnMiniPlayer(player, miniplayer, true);
        setupQueuePlayerControls(media, player, node);
      }
    }
  });
}

function playMiniPlayerItem(node, player, currentItem) {
  if (TFL.scrollPlayer === null) {
      var media_box = $(node).closest(".app-media-player");
      spawnMiniPlayer(player, media_box);
  }
  writeToLocalStorage("nowPlaying", currentItem);

  player.setSrc(currentItem.audio_url);
  $(SCROLLPLAYER)
    .find(".app-media-audio-static-poster")
    .attr("src", currentItem.player_poster_image);
  $(".app-miniplayer-title").text(currentItem.title);
  TFL.sync_data[currentItem.audio_url] = {
    resource_type: currentItem.resource_type,
    resource_id: currentItem.resource_id,
    resource_format: "audio",
    seconds: currentItem.seconds
  };
  $("#scroll-player-origin")
    .attr("href", currentItem.url)
    .attr("data-pjax", "true");

  // Set current item in queue
  var currentId = currentItem.tracking_id;
  var currentElement = $(LISTENQUEUE).find(`[data-tracking-id='${currentId}']`).first();
  if (currentElement.length > 0) {
    setItemAsCurrentItem(currentElement);
  }

  updateMediaNextButtonStatus();
  var progress = (currentItem.progress) ? currentItem.progress : null;
  getMediaProgress({'seconds': progress}, player);

  player.play();
  $(".app-miniplayer-content")
    .css("opacity", 0.5)
    .fadeTo("slow", 1);
}

function playMiniPlayerThisItem(item) {
  var player = null;
  var node = null;
  if (TFL.scrollPlayer !== null) {
    player = TFL.scrollPlayer;
  } else if (TFL.mainPlayer !== null) {
    node = $(".app-media-content").first();
    var player_id = $(TFL.mainPlayer).find(".mejs-container").first().attr("id");
    player = mejs.players[player_id];
  } else {
    return createQueueMediaPlayerItem(
      item.resource_type,
      item.resource_id
    );
  }
  playMiniPlayerItem(node, player, item);
}


function playMiniPlayerPreviousItem(node, player) {
  var prevItem = getPreviousListenQueueItem();
  if (prevItem) {
    if (checkIfListenQueueExpired(prevItem.expiration)) {
      var msg =
        'Unable to play "' +
        prevItem.title +
        '".  This item is no longer available.';
      displayErrorNotification(msg);
      return playMiniPlayerPreviousItem(node, player);
    }
    playMiniPlayerItem(node, player, prevItem);
  } else {
    clearNowPlaying();
    hideQueuePlayer();
  }
}

function playMiniPlayerNextItem(node, player) {
  var nextItem = getNextListenQueueItem();
  if (nextItem) {
    if (checkIfListenQueueExpired(nextItem.expiration)) {
      var msg =
        'Unable to play "' +
        nextItem.title +
        '".  This item is no longer available.';
      displayErrorNotification(msg);
      return playMiniPlayerNextItem(node, player);
    }
    playMiniPlayerItem(node, player, nextItem);
  } else {
    clearNowPlaying();
    hideQueuePlayer();
  }
}

function createQueueMediaPlayerItem(resource_type, resource_id) {
  var url = "/api/listenqueue/".concat(
    resource_type,
    "/",
    resource_id,
    "/player/"
  );
  $.ajax({
    type: "GET",
    url: url
  })
    .done(function(myPlayerBlock) {
      $("#queue_player_media_item_wrapper").html(myPlayerBlock);
      var myPlayer = $("#queue_player_item .app-media-player");
      createMediaElementPlayer("#queue_player_item audio", myPlayer);
      $("#queue_player_media_item_wrapper").html();
      updateMediaNextButtonStatus();
    })
    .fail(function() {
      displayErrorNotification(
        "There was an error retrieving media from your listen queue."
      );
      return false;
    });
}

function initializeMedia() {
  // Media Player
  // updateBroadcastImages();
  configurePlayerControls();
  toggleAudioPlayPause();

  $(".app-media-container video, .app-media-container audio").each(function() {
    // Skip currently playing miniplayer media
    if (
      TFL.scrollPlayer !== null &&
      TFL.scrollPlayer.getSrc() === $(this).attr("src")
    ) {
      var wrapper = $(this).closest(".app-media-player");
      $(wrapper)
        .find(".app-media-content")
        .detach();
      $(wrapper)
        .find(".app-media-overlay")
        .removeClass("hide");
    } else {
      createMediaElementPlayer(this, false);
    }
  });

  // Clicking on overlay returns media to default player.
  $(".app-media-container")
    .off("click", ".app-main-player")
    .on("click", ".app-main-player", function() {
      $(SCROLLPLAYER)
        .find(".app-main-player")
        .click();
    });
}

// Handles Audio poster play/pause toggle
function toggleAudioPlayPause() {
  $(".app-media-container").on("click", ".app-media-audio-poster", function(
    ev
  ) {
    var audio_player_wrapper = $(ev.target).closest(".app-media-content");
    var audio_player = $(audio_player_wrapper).find("audio");
    if (audio_player && audio_player[0]) {
      if (audio_player[0].paused) {
        audio_player[0].play();
      } else {
        audio_player[0].pause();
      }
    }
  });
}

// Shows the Play icon overlay for a second when playing
function showPlayingOverlay(player) {
  var player_wrapper = $(player).closest(".media-player");
  var playing_overlay = $(player_wrapper).find(".mejs-overlay-play");
  // Only on Main Player
  if (playing_overlay.length) {
    // move current media back to default player
    if (hasMiniPlayer()) {
      returnToDefaultPlayer(true);
    }
    TFL.mainPlayer = player_wrapper;
    scrollSpawnMiniPlayer();

    $(playing_overlay).addClass("overlay-playing");
    $(playing_overlay)
      .finish()
      .css("opacity", 1)
      .show();
    $(playing_overlay).fadeTo(750, 0, function() {
      $(this).removeClass("overlay-playing");
    });
  }
}

// Shows the Paused icon overlay for a second when playing
function showPausedOverlay(player) {
  var paused_overlay = $(player)
    .closest(".media-player")
    .find(".mejs-overlay-play");
  // Only on Main Player
  if (paused_overlay.length) {
    $(paused_overlay)
      .finish()
      .css("opacity", 1)
      .show();
    $(paused_overlay)
      .delay(1000)
      .fadeTo(1250, 0);
  }
}

// Set Audio as default after rendering Video
function setAudioAsDefault() {
  $(".app-listen-watch .btn-listen").each(function(i, el) {
    showMediaAudio(el);
  });
}

function scrollToPlayer() {
  $("html,body").animate(
    { scrollTop: $(".app-media-player-wrapper").offset().top - 80 },
    500
  );
}

// corrects issue with improper miniplayer video sizing
function fixVideoSize() {
  if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    navigator.appVersion.indexOf("Trident/") > 0
  ) {
    var evt = document.createEvent("UIEvents");
    evt.initUIEvent("resize", true, false, window, 0);
    window.dispatchEvent(evt);
  } else {
    window.dispatchEvent(new Event("resize"));
  }
}

function hideMiniPlayer() {
  $(SCROLLPLAYER)
    .addClass("remove")
    .removeClass("slide-down")
    .hide();
}

function showMiniPlayer() {
  $(SCROLLPLAYER)
    .removeClass("remove")
    .show();
}

function hasMiniPlayer() {
  if (TFL.scrollPlayer !== null && TFL.scrollPlayer.media != null) {
    return true;
  }
  return false;
}

function hasMainPlayer() {
  if (TFL.mainPlayer !== null) {
    return true;
  }
  return false;
}

function setupMediaTypeToggle() {
  // Set the current Media Type (Listen or Watch)
  $(SITE_WRAPPER).on("click", ".app-listen-watch a:not(.active)", function(ev) {
    var target = $(ev.target);

    // Pause All on Context Change.
    pauseAllPlayers();

    if (target.hasClass("app-listen")) {
      showMediaAudio(target);
    } else if (target.hasClass("app-watch")) {
      showMediaVideo(target);
    }

    // Cancel the stored mainPlayer
    TFL.mainPlayer = null;
    return;
  });
}

function showMediaAudio(target) {
  var type = "audio";
  var mediaWrapper = $(target.closest(".app-media-player-wrapper"));
  if (mediaWrapper.length > 0) {
    mediaWrapper.find(".audio-player").removeClass("hide");
    mediaWrapper.find(".video-player").addClass("hide");
  }
  toggleListenWatch(type, target);
  setMediaDownloadLinks(type, target);
}

function showMediaVideo(target) {
  var type = "video";
  var mediaWrapper = $(target.closest(".app-media-player-wrapper"));
  if (mediaWrapper.length > 0) {
    mediaWrapper.find(".video-player").removeClass("hide");
    mediaWrapper.find(".audio-player").addClass("hide");
  }
  toggleListenWatch(type, target);
  setMediaDownloadLinks(type, target);
}

function toggleListenWatch(type, target) {
  var mediaWrapper = target.closest(".app-media-player-wrapper");
  $(mediaWrapper)
    .find(".app-listen-watch a")
    .removeClass("active");
  if (type === "audio") {
    $(mediaWrapper)
      .find(".app-listen-watch a.app-listen")
      .addClass("active");
    $(".app-add-queue-popover")
      .popover("enable")
      .removeClass("disabled");
  } else if (type === "video") {
    $(mediaWrapper)
      .find(".app-listen-watch a.app-watch")
      .addClass("active");
    $(".app-add-queue-popover")
      .popover("disable")
      .addClass("disabled");
  }
}

// Note: Purposefully does NOT pause miniPlayer
function pauseAllPlayers() {
  $(".app-media-container video, .app-media-container audio").trigger("pause");
}

function setupMiniPlayerToggles() {
  // Audio title shows/collapses poster
  $(SCROLLPLAYER).on("click hidePoster", ".scroll-player-header", function() {
    if (
      $(".app-miniplayer-content .app-media-content").hasClass(
        "audio-container"
      )
    ) {
      if ($(SCROLLPLAYER).hasClass("slide-down")) {
        $(SCROLLPLAYER).removeClass("slide-down");
        $(".app-miniplayer-content img").slideDown(75);
      } else {
        $(".app-miniplayer-content img").slideUp(150, function() {
          $(SCROLLPLAYER).addClass("slide-down");
        });
      }
    }
  });
}

function setMediaDownloadLinks(type, target) {
  var linkto = "";
  var container = target.closest(".app-media-container");
  if ($(container).length) {
    if (type === "video") {
      linkto = $(container)
        .find(".video-player")
        .data("src");
    } else if (type === "audio") {
      linkto = $(container)
        .find(".audio-player")
        .data("src");
    }
    linkto = linkto.replace(MEDIA_SRC, DOWNLOAD_SRC);
    $(container)
      .find(".app-download-link")
      .attr("href", linkto)
      .attr("data-resource_format", type);
  }
}

function getPlayerPositionHeight() {
  var myTop = $(".media-player-container").position().top;
  if (myTop > 0) {
    return myTop + $(".media-player-container").outerHeight(true);
  }
  return 700;
}

function scrollSpawnMiniPlayer() {
  var mediaScrollFunction = debounce(function() {
    if (hasMainPlayer()) {
      var y = window.pageYOffset;
      if (y > getPlayerPositionHeight()) {
        var miniButton = $(TFL.mainPlayer).find(".app-miniplayer-button");
        if (miniButton.length) {
          $(miniButton)
            .first()
            .click();
          window.removeEventListener("scroll", mediaScrollFunction);
        }
      }
    }
  }, 100);
  window.addEventListener("scroll", mediaScrollFunction);
}

function returnToDefaultPlayer(pause) {
  // Handle current MiniPlayer
  try {
    if (TFL.scrollPlayer !== null && TFL.scrollPlayer.getSrc() !== null) {
      // Unset the defaultplayer toggle
      TFL.scrollPlayer.options.defaultplayer = false;

      if (pause) {
        TFL.scrollPlayer.pause();
      }
      // Return to default if on the same page
      $("#site-canvas .app-media-player").each(function() {
        if (
          $(this).data("src") == TFL.scrollPlayer.getSrc() &&
          !$(this).find(".app-media-content").length
        ) {
          // Check playing status to prevent IE/Edge stopping bug on appendTo call
          var paused = TFL.scrollPlayer.paused;
          $(".app-miniplayer-content .audio-container img").show();
          $(".app-miniplayer-content .app-media-content").appendTo(this);
          $(this)
            .find(".app-media-overlay")
            .addClass("hide");
          fixVideoSize();
          if (!pause) {
            if (!paused && TFL.scrollPlayer.paused !== paused) {
              TFL.scrollPlayer.play();
            }
            TFL.mainPlayer = this;
            scrollSpawnMiniPlayer();

            if ($(this).hasClass("video-player")) {
              showMediaVideo(this);
            } else {
              showMediaAudio(this);
            }
          }
        }
      });

      // Unset scrollPlayer
      hideMiniPlayer();
      TFL.scrollPlayer = null;
      $("body").removeClass("player-open");
    }
  } catch (err) {
    // Ignore if we don't have anything
  }

  // Clear out the miniplayer content.
  $(".app-miniplayer-content").html("");
}

function spawnMiniPlayer(player, media_box, nospawn = false) {
  var media_content = $(media_box)
    .find(".app-media-content")
    .first();
  var overlay = $(media_content).siblings(".app-media-overlay");

  // move current media back to default player
  returnToDefaultPlayer(true);

  // remove stored main player item
  TFL.mainPlayer = null;

  // assign the new scrollplayer
  TFL.scrollPlayer = player;

  // populate the miniplayer elements
  $(".app-miniplayer-title").text($(media_box).data("title"));
  $(SCROLLPLAYER).toggleClass(
    "video",
    !$(media_content).hasClass("audio-container")
  );

  // Check playing status to prevent IE/Edge stopping bug on appendTo call
  var paused = false;
  if (TFL.scrollPlayer) {
    paused = TFL.scrollPlayer.paused;
  }
  $(media_content).appendTo(".app-miniplayer-content");
  if (TFL.scrollPlayer && !paused && TFL.scrollPlayer.paused !== paused) {
    TFL.scrollPlayer.play();
  }

  $(overlay).removeClass("hide");
  if (nospawn) {
    $(SCROLLPLAYER)
      .removeClass("remove")
      .fadeIn("slow");
    fixVideoSize();
  } else {
    showMiniPlayer();
    fixVideoSize();
    var destination = $(SCROLLPLAYER).offset();
    var origin = $(media_box)
      .find(".action-box span")
      .offset();

    if (origin) {
    // Set starting height based on miniplayer size
      origin.top = origin.top - 220;
      $(SCROLLPLAYER).offset(origin);
    }

    // Take into account scrollTop
    destination.top = destination.top - $(window).scrollTop();
    $(SCROLLPLAYER)
      .find(".mejs-time-rail")
      .css({ opacity: 0 });
    $(SCROLLPLAYER).animate(
      {
        top: destination.top,
        left: destination.left
      },
      {
        duration: "600",
        easing: "linear",
        queue: false,
        complete: function() {
          $(SCROLLPLAYER).css({ top: "", left: "" });
          $(SCROLLPLAYER)
            .find(".mejs-time-rail")
            .css({ opacity: 1 });
          if ($(window).width() < 753) {
            $(".scroll-player-header").trigger("hidePoster");
          }
        }
      }
    );
  }
  // Set pjax miniplayer notifier
  $("body").addClass("player-open");
}

function configurePlayerControls() {
  MediaElementPlayer.prototype.buildminiplayer = function(player, controls) {
    var playerid = player.id;
    // create the miniplayer button
    $(
      '<div class="app-miniplayer-button mejs-button mejs-miniplayer-button">' +
        '<button type="button" aria-controls="' +
        playerid +
        '" title="Play in MiniPlayer" aria-label="MiniPlayer" tabindex="0"></button>' +
        "</div>"
    )
      // append it to the toolbar
      .appendTo(controls)
      // add a click toggle event
      .click(function() {
        var media_box = $(controls).closest(".app-media-player");
        // Set the URL for a return to default.
        if ($(media_box)[0].hasAttribute("data-origin_page")) {
          $("#scroll-player-origin")
            .attr("href", $(media_box).attr("data-origin_page"))
            .attr("data-pjax", "false");
        } else {
          $("#scroll-player-origin")
            .attr("href", getCleanUrl(window.location.href))
            .attr("data-pjax", "true");
        }
        spawnMiniPlayer(player, media_box);
      });
  };
  MediaElementPlayer.prototype.builddefaultplayer = function(player, controls) {
    var playerid = player.id;
    // create the miniplayer button
    $(
      '<div class="app-main-player mejs-button mejs-defaultplayer-button">' +
        '<button type="button" aria-controls="' +
        playerid +
        '" title="Play in Main Player" aria-label="MainPlayer" tabindex="0"></button>' +
        "</div>"
    )
      // append it to the toolbar
      .appendTo(controls)
      // add a click toggle event
      .click(function() {
        var here = getCleanUrl(window.location.href);
        var origin = $("#scroll-player-origin").attr("href");

        // Are we still on the same page?
        if (here === origin) {
          returnToDefaultPlayer(false);
          scrollToPlayer();
        } else {
          player.options.defaultplayer = true;
          // handle MoM miniplayer exception
          $("#scroll-player-origin")[0].click();
        }
      });
  };
  MediaElementPlayer.prototype.buildcloseminiplayer = function(
    player,
    controls
  ) {
    var playerid = player.id;
    // create the miniplayer button
    $(
      '<div class="app-miniplayer-close mejs-button mejs-miniplayer-close scroll-player-close scroll-player-icon">' +
        '<button type="button" aria-controls="' +
        playerid +
        '" title="Close Player" aria-label="ClosePlayer" tabindex="0"></button>' +
        "</div>"
    )
      // append it to the toolbar
      .appendTo(controls)
      // add a click toggle event
      .click(function() {
        returnToDefaultPlayer(true);
        var yousure = confirm("Would you like to clear your Listen Queue & History?");
        if (yousure) {
          clearListenQueue();
          displayEmptyQueue(LISTENQUEUE);
        } else {
          setCurrentQueueItemToNext();
        }
      });
  };
  MediaElementPlayer.prototype.buildlistenqueue = function(player, controls) {
    var playerid = player.id;
    // create the listening queue button
    $(
      '<div class="app-queue-button mejs-button mejs-queue-button">' +
        '<button type="button" aria-controls="' +
        playerid +
        '" title="Listen Queue" aria-label="ListenQueue" tabindex="0" data-toggle="modal" data-target="#queueModal"></button>' +
        "</div>"
    )
      // append it to the toolbar
      .appendTo(controls);
  };

  MediaElementPlayer.prototype.buildskiptonext = function(player, controls) {
    var playerid = player.id;
    $(
      '<div class="js-skip-to-next mejs-button mejs-skip disabled">' +
        '<button type="button" aria-controls="' +
        playerid +
        '" title="Next" aria-label="Next" tabindex="0"></button>' +
        "</div>"
    )
      // append it to the toolbar
      .appendTo(controls)
      // add a click toggle event
      .click(function(ev) {
        if (!$(ev.currentTarget).hasClass("disabled")) {
          playMiniPlayerNextItem(controls, player);
        }
      });
  };

  MediaElementPlayer.prototype.buildskiptoprev = function(player, controls) {
    var playerid = player.id;
    $(
      '<div class="js-skip-to-prev mejs-button mejs-previous disabled">' +
        '<button type="button" aria-controls="' +
        playerid +
        '" title="Previous" aria-label="Previous" tabindex="0"></button>' +
        "</div>"
    )
      // append it to the toolbar
      .appendTo(controls)
      // add a click toggle event
      .click(function(ev) {
        if (!$(ev.currentTarget).hasClass("disabled")) {
          playMiniPlayerPreviousItem(controls, player);
        }
      });
  };
}

function updateMediaNextButtonStatus() {
  var localStorageQueue = getListenQueue();
  return setMediaNextButtonStatus(localStorageQueue);
}

function setMediaNextButtonStatus(queue) {
  var disable_next = true;
  var disable_prev = true;

  // Must have a queue
  if (queue && queue.length > 0) {
    var current = getNowPlayingData();
    var currentIndex;
    // Must have an active current item
    if (current) {
      currentIndex = findQueueItemIndex(queue, current.tracking_id);
      // current item must be in the queue.
      if (currentIndex > -1) {
        if ((currentIndex + 1) < queue.length) {
          disable_next = false;
        }
        if (currentIndex > 0) {
          disable_prev = false;
        }
      }
    }
  }
  $(".js-skip-to-next").toggleClass("disabled", disable_next);
  $(".js-skip-to-prev").toggleClass("disabled", disable_prev);
}

// ----------------------------------------------------  END Media Player

// ----------------------------------------------------  Favorites
function setupFavoriteToggle() {
  $("body").on("click", ".app-favorite-link", function(ev) {
    ev.preventDefault();
    var node = $(ev.currentTarget);
    var url = "/api/favorites/sync/";
    var favorite = node.hasClass("favorite");
    var sync_data = {
      resource_type: node.attr("data-resource_type"),
      resource_id: node.attr("data-resource_id")
    };
    if (favorite) {
      sync_data["deleted"] = true;
    }
    $.ajax({
      type: "POST",
      url: url,
      data: JSON.stringify({
        data: [sync_data],
        last_sync: new Date().toISOString()
      }),
      contentType: "application/json"
    })
      .done(function() {
        // All Set
        node.toggleClass("favorite");
        if (node.hasClass('js-force-pjax')) {
          forcePjaxReload();
        }
      })
      .fail(function() {
        displayErrorNotification(
          "Error Favoriting this item. Please Try Again."
        );
      });
  });
}

function forcePjaxReload() {
  var currentUrl = getCleanUrl(window.location.href);
  if (currentUrl) {
    var container_id = SITE_WRAPPER;
    if ($.support.pjax) {
      $.pjax({
        url: currentUrl,
        container: container_id
      });

      // WEBKIT NOTE:
      //
      // Old webkit browsers have trouble with position: fixed + transform. This
      // is our attempt to disable NProgress those older webkit versions. For
      // webkit browsers that don't have websockets support we don't enable NProgress.
      if (
        $("html").hasClass("websockets") ||
        !("WebkitTransform" in document.body.style)
      ) {
        NProgress.configure({ parent: "body" });
        NProgress.start();
      }
    } else {
      window.location = currentUrl;
    }
  }
}

// ----------------------------------------------------  End Favorites

// ----------------------------------------------------  Set User Details

function setUserDetailsPref(field_name, field_value, successFunction, failureFunction) {
  $.ajax({
      type: "POST",
      url: "/account/setuserdetail/",
      data: { 'hide': field_value, 'field_name': field_name },
    })
    .done(function(inRes){ (inRes.data && inRes.data.length) ? successFunction() : failureFunction(); })
    .fail(failureFunction);
}
// ----------------------------------------------------  End Set User Details




// ----------------------------------------------------  Toggle Section Show/Hide Account Pref
function setupToggleShowHideAccountPref() {
  $("body").on('change', ".js-toggle-hide-account-pref input", function(ev) {
    var input = $(ev.currentTarget);
    var msg = "This section will be ";
    var t = $(input).parent(".js-toggle-hide-account-pref");
    var set_val = $(input).is(":checked");
    var set_str = set_val ? 'false' : 'true';
    msg += set_val ? 'displayed.' : 'hidden.';


    var actionSuccess = function() {
      $(t).toggleClass('active', set_val);
      displayInfoNotification(msg);
    };

    var actionFailure = function() {
      displayErrorNotification("There was a problem setting that preference.");
    };

    setUserDetailsPref($(input).attr('name'), set_str, actionSuccess, actionFailure);
  });
}

function setupToggleRememberMePref() {
  $("body").on('change', ".js-toggle-remember-me-pref input", function(ev) {
    var input = $(ev.currentTarget);
    var t = $(input).parent(".js-toggle-remember-me-pref");
    var set_val = $(input).is(":checked");
    var set_str = set_val ? 'false' : 'true';

    var actionSuccess = function() {
      $(t).toggleClass('active', set_val);
      displayInfoNotification('Remember Me setting updated.');
    };

    var actionFailure = function() {
      displayErrorNotification("There was a problem setting that preference.");
    };

    setUserDetailsPref($(input).attr('name'), set_str, actionSuccess, actionFailure);
  });
}


// ----------------------------------------------------  End Toggle Section

// ----------------------------------------------------  Toggle Home Page Show/Hide Section
function setupHomePageSectionToggle() {
  $("body").on('click',"[data-profile-field]", function(ev) {
    /* this function *only* hides a slider. there is no mechanism to turn it back on outside of the profile tab */
    var jqTarget = $(ev.currentTarget);
    var jqSlider = jqTarget.parents(".slider-group");
    var stateField = jqTarget.data("profile-field");
    var stateUpdating = jqSlider.attr("data-profile-updating");

    // stop potential link or closing behavior
    ev.preventDefault();
    ev.stopImmediatePropagation();

    // stop spammy clicking by maintaining an "updating" attribute state
    if (stateUpdating) {
      return;
    } else {
      jqSlider.attr("data-profile-updating", true);
    }

    // fade the slider partially to show waiting for async response
    jqSlider.stop(true, false).animate({opacity:0.5}, 500);
    // async update the account setting
    var actionSuccess = function(){
      jqSlider.stop(true, false).fadeOut(800);
      displayInfoNotification("This section is hidden. You may reset this in your account settings.");
    };
    var actionFailure = function(){
      jqSlider.removeAttr("data-profile-updating");
      jqSlider.stop(true, false).animate({opacity:1}, 800);
      displayErrorNotification("There was a problem setting this preference.");
    };
    setUserDetailsPref(stateField, 'true', actionSuccess, actionFailure);
  });
}

// ----------------------------------------------------  End Home Page Show/Hide Section

// ----------------------------------------------------  Downloads
function setupDownloadLogging() {
  $(document).on("click", "a", function(event) {
    var link = event.currentTarget;
    if (
      link.href.match(/https?:\/\/downloads\.truthforlife\.org\//) &&
      link.getAttribute("data-resource_id") &&
      link.getAttribute("data-resource_type")
    ) {
      var request_body = [
        {
          resource_type: link.getAttribute("data-resource_type"),
          resource_id: link.getAttribute("data-resource_id"),
          resource_format: link.getAttribute("data-resource_format") || null
        }
      ];
      $.ajax({
        url: "/api/downloads/add/",
        type: "POST",
        data: JSON.stringify(request_body),
        contentType: "application/json"
      });
    }
  });
}

// ----------------------------------------------------  End Downloads

// ----------------------------------------------------  Notifications
function clearNotification() {
  TFL.notification = false;
  $("#site_notification")
    .removeClass("active")
    .removeClass("success")
    .removeClass("info")
    .removeClass("error");
  $("#site_notification .notification_text").html(" ");
}

/* eslint-disable-next-line no-unused-vars */
function displaySuccessNotification(msg) {
  return displayNotificationMessage(msg, "success");
}

function displayErrorNotification(msg) {
  return displayNotificationMessage(msg, "error");
}

function displayInfoNotification(msg) {
  return displayNotificationMessage(msg, "info");
}

function displayNotificationMessage(msg, msg_type) {
  if (TFL.notification) {
    clearTimeout(TFL.notification);
    clearNotification();
  }
  $("#site_notification .notification_text").html(msg);
  $("#site_notification")
    .addClass("active")
    .addClass(msg_type);

  TFL.notification = setTimeout(function() {
    clearNotification();
  }, 5000);
}

// ----------------------------------------------------  End Notifications

// ----------------------------------------------------  Local Storage

function writeToLocalStorage(key, data) {
  if (key && data) {
    return window.localStorage.setItem(key, JSON.stringify(data));
  }
  return false;
}

function readFromLocalStorage(key) {
  var data = window.localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  }
  return false;
}

function removeFromLocalStorage(key) {
  window.localStorage.removeItem(key);
}

// ----------------------------------------------------  End Local Storage

// ----------------------------------------------------  Listen Queue

// Setup
function setupListenQueue() {
  var queue = getListenQueue();
  displayListenQueueData(queue);
  initializeSortArrows();
  initializeDragAndDrop();
  initializePlayNow();
  removeItemFromListenQueue();
  favoriteItemFromListenQueue();
  removeAllFromListenQueue();
  checkIfListenQueue(queue);
  addToListenQueue();
  setScrollOnSpawn();
  closeQueuePopoverOnScroll();
  closeQueueOnOutsideClick();
  syncQueueAcrossTabs();
}


// Queue Media Player
function displayQueuePlayerItem(current) {
  if (current) {
    if ($('.js-queue-player').attr('data-tracking-id') != current.tracking_id) {
      $(".js-queue-player-title").text(current.title);

      var time_display = "";
      if (current.duration) {
        time_display = formatPlayerTime(current.duration);
      }
      $(".js-queue-player-time-total").text(time_display).attr("data-duration", current.duration);
    }
    // Media Item Action Items
    var resourceUrl = current.url;
    var resourceType = getMediaResourceType(current.resource_type);

    $('.js-queue-player').find(".js-add-queue-popover").first().popover('destroy');
    var libraryAdd = '';
    if (TFL.myFavorites !== false) {
      var favorited = false;
      favorited = $.grep(TFL.myFavorites, function(obj) { return obj.resource_id == current.resource_id && obj.resource_type == current.resource_type && obj.deleted !== true})[0];
      libraryAdd =  (favorited ?
        '<div class="queue-button-wrapper js-add-favorite remove" data-resource_type="' + current.resource_type + '" data-resource_id="' + current.resource_id + '">Remove From My Library</div>' :
        '<div class="queue-button-wrapper js-add-favorite" data-resource_type="' + current.resource_type + '" data-resource_id="' + current.resource_id + '">Add to My Library</div>');
    }

    var contents = '<div style="color:#000;" class="app-queue-popover-menu">' +
                   libraryAdd +
                   '<div class="queue-button-wrapper"><a href="' + resourceUrl + '">Go to ' + resourceType + '</a></div>' +
                   '<div class="js-close-popover queue-button-wrapper">Cancel</div></div>';

    setTimeout(() => {
      $('.js-queue-player').find(".js-add-queue-popover").popover({
        trigger: "focus",
        placement: "left",
        html: true,
        content: contents,
      });
    }, 1000);
    showQueuePlayer();
  } else {
    hideQueuePlayer();
  }
}



function setNowPlaying(data) {
  displayQueuePlayerItem(data);

  var current = getNowPlayingData();
  // Verify this item is in the queue
  var localStorageQueue = getListenQueue();
  var currentIndex;
  var newQueue;
  if (current) {
    currentIndex = findQueueItemIndex(localStorageQueue, current.tracking_id);
  } else {
    currentIndex = localStorageQueue.length - 1;
    current = localStorageQueue[currentIndex];
  }

  if (currentIndex > -1) {
    // Not the same media
    if (current.resource_id != data.resource_id || current.resource_type != data.resource_type) {
      // Place this item after the current item
      currentIndex = currentIndex + 1;
      localStorageQueue.splice(currentIndex, 0, data);
      setListenQueue(localStorageQueue);
      writeToLocalStorage("nowPlaying", data);
      displayListenQueueData(localStorageQueue);
    } else if (data.tracking_id != localStorageQueue[currentIndex].tracking_id) {
      data.tracking_id = localStorageQueue[currentIndex].tracking_id;
      return writeToLocalStorage("nowPlaying", data);
    } else {
      return;
    }
  } else {
      // Put this item at the end
      newQueue = [...localStorageQueue, data];
      setListenQueue(newQueue);
      writeToLocalStorage("nowPlaying", data);
      displayListenQueueData(newQueue);
  }
}

function initializePlayNow() {
  $("#queueModal").on("click", ".js-queue-playnow", function(ev) {
    var myRow = $(ev.currentTarget).closest(".queue-row");
    if (myRow.hasClass("current") && !$('.js-queue-player').hasClass('hidden')) {
      if (!$(".js-queue-player-play").hasClass("hidden")) {
        $(".js-queue-player-play").click();
      } else {
        $(".js-queue-player-pause").click();
      }
    } else {
      var myTrackingId = $(myRow).attr("data-tracking-id");
      if (myTrackingId.length) {
        var queue = getListenQueue();
        var currentItem = findQueueItemIndex(queue, myTrackingId);
        if (currentItem > -1) {
          playMiniPlayerThisItem(queue[currentItem]);
          displayCurrentQueueItem(queue);
        }
      }
    }
  });
}

function setQueuePlayerPaused() {
  $('.js-queue-player-pause').addClass("hidden");
  $('.js-queue-player-play').removeClass("hidden");
  $('.queue-row.current').removeClass('playing');
}

function setQueuePlayerPlaying() {
  $('.js-queue-player-pause').removeClass("hidden");
  $('.js-queue-player-play').addClass("hidden");
  $('.queue-row.current').addClass('playing');
}

function showQueuePlayer() {
  $(".js-queue-player").removeClass("hidden");
}

function hideQueuePlayer() {
  $(".js-queue-player").addClass("hidden");
}

function setQueuePlayerTime(seconds) {
  seconds = Math.floor(seconds);
  var display_time = formatPlayerTime(seconds);
  if ($('.js-queue-player-time-current').text() != display_time) {
    $('.js-queue-player-time-current').text(display_time);

    var duration = $('.js-queue-player-time-total').attr("data-duration");
    if (duration) {
      var progressWidth = getPlayerProgressPercentage(seconds, duration);
      $('.js-queue-player-progress-current, .queue-row.current .js-player-progress-current').css("width", `${progressWidth}%`);
    }
  }
}

function setupQueuePlayerControls(media, player, node) {
  // Play/Pause
  $("body").off("click", ".js-queue-player-play");
  $("body").off("click", ".js-queue-player-pause");
  $("body").off("click", ".js-queue-player-next");
  $("body").off("click", ".js-queue-player-previous");
  $("#queueModal").off("mouseup", ".js-queue-player-progress-bar");
  $("#queueModal").off("mousemove", ".js-queue-player-progress-bar");

  $("body").on("click", ".js-queue-player-play", function() {
    if (!$(this).hasClass('disabled')) {
      media.play();
    }
  });
  $("body").on("click", ".js-queue-player-pause", function() {
      if (!$(this).hasClass('disabled')) {
        media.pause();
      }
  });
  $("body").on("click", ".js-queue-player-next", function() {
    if (!$(this).hasClass('disabled')) {
      playMiniPlayerNextItem(node, player);
    }
  });
  $("body").on("click", ".js-queue-player-previous", function() {
    if (!$(this).hasClass('disabled')) {
      playMiniPlayerPreviousItem(node, player);
    }
  });
  $("#queueModal").on("mouseup", ".js-queue-player-progress-bar", function(e) {
    var x = e.pageX - $(this).offset().left;
    if (x) {
      var time_offset = 'translateX(' + x + 'px)';
      $(".js-queue-player-progress-time-handle").css("transform", time_offset);
      var full_width = $(".js-queue-player-progress-bar").width();
      if (full_width) {
        var percentage = (x/full_width);
        var duration = $('.js-queue-player-time-total').attr("data-duration");
        if (duration && percentage) {
          var progress = duration * percentage;
          setMediaProgressTime(media, progress);
          var progressWidth = Math.floor(percentage * 100);
          $('.js-queue-player-progress-current, .queue-row.current .js-player-progress-current').css("width", `${progressWidth}%`);
        }
      }
    }
  });
  $("#queueModal").on("mousemove", ".js-queue-player-progress-bar", function(e) {
    var x = e.pageX - $(this).offset().left;
    if (x) {
      var time_offset = x + 'px';
      $(".js-queue-player-progress-time-seek").css("width", time_offset);
      $(".js-queue-player-progress-time-handle").css("transform", 'translateX(' + time_offset + ')');
    }
  });
}

function closeQueuePopoverOnScroll() {
  var queueScrollFunction = debounce(function() {
    $(".popover").popover('hide');
  }, 100);
  $(".js-up-next-section").scroll(queueScrollFunction);
}

function closeQueueOnOutsideClick() {
  $("#queueModal").on("click", ".modal-dialog", function(e) {
    if ($(e.target).hasClass('modal-dialog')) {
      $('#queueModal').modal('hide');
    }
  });
}


// Actions
function addToListenQueue() {
  addQueueToLocalStorage(".app-play-later");
  addQueueToLocalStorage(".app-play-next");
}

function addQueueToLocalStorage(className) {
  $("body").on("click", className, debounce(
    function(ev) {
      var node = $(ev.currentTarget);
      var response = listenQueueItemAPIResponse(
        node.attr("data-resource_id"),
        node.attr("data-resource_type")
      );
      return response.then(queueData => {
        var newQueue; // Replacement Queue data
        var localStorageQueue; // Current Queue Data
        var current; // Current Queue Item
        var currentIndex; // Current Queue index value
        var msg; // Notification information

        if (queueData instanceof Array) {
          // Add Series to Queue
          localStorageQueue = getListenQueue();

          // Assign tracking id for every entry
          for (var i=0; i<queueData.length; ++i) {
            queueData[i].tracking_id =`${queueData[i].resource_id}_${Date.now()}`;
          }
          if (className === ".app-play-later") {
            newQueue = localStorageQueue.concat(queueData)
            setListenQueue(newQueue);
          }
          else {
            current = getNowPlayingData();
            if (current) {
              currentIndex = findQueueItemIndex(localStorageQueue, current.tracking_id);
            }

            if (currentIndex > -1) {
              currentIndex = currentIndex + 1;
              localStorageQueue.splice(currentIndex, 0, ...queueData);
              setListenQueue(localStorageQueue);
            }
            else {
              newQueue = queueData.concat(localStorageQueue)
              setListenQueue(newQueue);
            }
          }
          displayListenQueue();
          disableArrows();
          msg =  queueData.length + " items added to your listen queue.";
          displayInfoNotification(msg);

          // Set the first item to current if we are spawning the player
          queueData = queueData[0];
        }
        else {
          // Used to track element for removal or reordering
          var uniqueId = `${queueData.resource_id}_${Date.now()}`;
          queueData.tracking_id = uniqueId;
          localStorageQueue = getListenQueue();
          var queueItem;
          if (className === ".app-play-later") {
            setListenQueue([...localStorageQueue, queueData]);
            queueItem = displayListenQueueItem(queueData);
            addItemToListenQueue(queueItem, 'last');
          } else {
            current = getNowPlayingData();
            if (current) {
              currentIndex = findQueueItemIndex(localStorageQueue, current.tracking_id);
            }

            if (currentIndex > -1) {
              currentIndex = currentIndex + 1;
              localStorageQueue.splice(currentIndex, 0, queueData);
              setListenQueue(localStorageQueue);
            } else {
              setListenQueue([queueData, ...localStorageQueue]);
            }
            queueItem = displayListenQueueItem(queueData);
            addItemToListenQueue(queueItem, 'next');
          }
          disableArrows();
          msg = queueData.title + " added to your listen queue.";
          displayInfoNotification(msg);
        }

        if (node.hasClass('app-show-player')) {
          if (node.hasClass('app-play-now')) {
            if (TFL.scrollPlayer) {
              TFL.scrollPlayer.pause();
              //hideMiniPlayer();
              clearNowPlaying();
              TFL.scrollPlayer.remove();
              TFL.scrollPlayer = null;
              $("body").removeClass("player-open");
            }
          }
          var queue = getListenQueue();
          if (!getCurrentQueueItem(queue, true)) {
            // Force queue item as current
            writeToLocalStorage("nowPlaying", queueData);
          }
          checkIfListenQueue(queue);

          if (node.hasClass('app-play-now')) {
              waitForScrollPlayer();
          }
        }
      });
    }, 500)
  );
}

function waitForScrollPlayer() {
  var interval = setInterval(function() {
    if (TFL.scrollPlayer) {
      clearInterval(interval);
      TFL.scrollPlayer.play();
    }
  }, 500);
}


function getPreviousListenQueueItem() {
  var localStorageQueue = getListenQueue();
  if (localStorageQueue.length < 1) {
    return false;
  }
  var current = getNowPlayingData();
  if (!current) {
    return false;
  }

  var currentIndex = findQueueItemIndex(localStorageQueue, current.tracking_id);
  if (currentIndex > 0) {
    return localStorageQueue[currentIndex - 1];
  }
  return false;
}

function getNextListenQueueItem() {
  var localStorageQueue = getListenQueue();
  if (localStorageQueue.length < 1) {
    return false;
  }
  var current = getNowPlayingData();
  if (!current) {
    return false;
  }

  var currentIndex = findQueueItemIndex(localStorageQueue, current.tracking_id);
  if ((currentIndex + 1) < localStorageQueue.length) {
    return localStorageQueue[currentIndex + 1];
  }
  return false;
}


// Helpers
function getMediaResourceType(rtype) {
  if (rtype) {
    var resourceType = rtype.charAt(0).toUpperCase() + rtype.slice(1);
    if (resourceType === "Bible") {
      resourceType = "Bible Passage";
    } else if (resourceType === "Messageofthemonth") {
      resourceType = "Message of the Month";
    } else if (resourceType === "Alistairbeggdevotional") {
      resourceType = "Alistair Begg Devotional";
    }
    return resourceType;
  }
  return rtype;
  }

function formatPlayerTime(current) {
  // Make seconds into minutes and seconds
  if (current && current > 0) {
    var minutes = Math.floor(current / 60);
    var seconds = ("00" + Math.floor(current - (minutes * 60))).slice(-2);
    return `${minutes}:${seconds}`;
  }
  return "0:00";
}

function checkIfListenQueueExpired(exp_date) {
  if (exp_date) {
    var today = new Date().toISOString().slice(0, 10);
    return today > exp_date;
  }
  return false;
}

function checkIfListenQueue(queue) {
  if (TFL.scrollPlayer === null && TFL.mainPlayer === null) {
    // Only Spawn the player if we have a current Item in the Queue
    var current = getCurrentQueueItem(queue, true);
    if (current) {
      createQueueMediaPlayerItem(
        current.resource_type,
        current.resource_id
      );
      $("#scroll-player-origin").attr("href", current.url).attr("data-pjax", "false");
      displayQueuePlayerItem(current);
    }
  }
}

function getResourceTypeFrontendName(resource_type) {
  return resource_type
    .replace("message", "sermon")
    .replace("broadcast", "program");
}


// Listen Queue Getters
function getNowPlayingData() {
  return readFromLocalStorage("nowPlaying");
}

function getCurrentQueueItem(queue, strict) {
  if (queue){
    var queue_length = queue.length;
    if (queue_length > 0) {
      var currentItem = getNowPlayingData();
      if (!currentItem) {
        // strict only returns confirmed Not Playing items
        if (strict) {
          return false;
        }
        currentItem = queue[queue_length-1];
      }
      if (strict) {
        var currentIndex = findQueueItemIndex(queue, currentItem.tracking_id);
        if (currentIndex > -1) {
          return currentItem;
        }
        return false;
      }
      return currentItem;
    }
  }
  return false;
}

function getListenQueue() {
  var queue = readFromLocalStorage("listenQueue");
  if (!queue) {
    return clearListenQueue();
  }
  // Filter out all Null and falsey values.
  queue = queue.filter(function(item) { return !!item; });
  return queue;
}

function getPlayerProgressPercentage(progress, duration) {
  if (progress !== null && duration) {
    var progressPercent = Math.floor((progress / duration) * 100);
    return progressPercent;
  }
  return false;
}

function findQueueItemIndex(data, tracking_id) {
  return data.findIndex(item => {
    return tracking_id === item.tracking_id;
  });
}


// Listen Queue Setters
function clearListenQueue() {
  return setListenQueue([]);
}

function clearNowPlaying() {
  return removeFromLocalStorage("nowPlaying");
}

function setListenQueue(data) {
  setMediaNextButtonStatus(data);
  return writeToLocalStorage("listenQueue", data);
}

function reorderLocalStorageItems(localStorageQueue, currentIndex, toIndex) {
  var resource = localStorageQueue[currentIndex];
  localStorageQueue.splice(currentIndex, 1);
  localStorageQueue.splice(toIndex, 0, resource);
  setListenQueue(localStorageQueue);
}

function addDataTrackingId(queueItem, classNames, id) {
  classNames.forEach(className => {
    queueItem.find(className).attr("data-tracking-id", id);
  });
}

function setCurrentQueueItemToNext() {
  var next = getNextListenQueueItem();
  if (next) {
    writeToLocalStorage("nowPlaying", next);
    displayListenQueue();
  }
  else {
    clearNowPlaying();
    hideQueuePlayer();
  }
}

function removeItemFromListenQueue() {
  $("body").on("click", ".js-queue-remove-item", function(ev) {
    var tracking_id = $(ev.currentTarget).attr("data-tracking-id");
    if (tracking_id) {
      $(ev.currentTarget).closest('.queue-row').addClass("shrink");
      var localStorageQueue = getListenQueue();
      var updatedQueue = localStorageQueue.filter(function(val) {
        return tracking_id.indexOf(val.tracking_id) === -1;
      });
      setListenQueue(updatedQueue);
      if (updatedQueue.length < 1) {
        displayEmptyQueue(LISTENQUEUE);
      } else {
        updateListenQueueDOM(updatedQueue);
      }
    }
  });
}

function favoriteItemFromListenQueue() {
  $("#queueModal").on("click", ".js-add-favorite", function(ev) {
    ev.preventDefault();
    var node = $(ev.currentTarget);
    var url = "/api/favorites/sync/";
    var favorite = node.hasClass("remove");
    var sync_data = {
      resource_type: node.attr("data-resource_type"),
      resource_id: node.attr("data-resource_id")
    };
    if (favorite) {
      sync_data["deleted"] = true;
    }
    $.ajax({
      type: "POST",
      url: url,
      data: JSON.stringify({
        data: [sync_data],
        last_sync: new Date().toISOString()
      }),
      contentType: "application/json"
    })
    .done(function() {
      // All Set
     displayListenQueue();

    })
    .fail(function() {
      displayErrorNotification(
        "Error Favoriting this item. Please Try Again."
      );
    });
  });
}

function queueItemIsPlaying(current) {
  if (current && current.playerId) {
    var playerBody = $('#'+current.playerId)[0];
    if (playerBody) {
      var player = playerBody.player;
      if (player) {
        return !!(player.currentTime > 0 && !player.paused && !player.ended && player.readyState > 2);
      }
    }
  }
  return false;
}


function removeAllFromListenQueue() {
  $("body").on("click", ".js-clear-queue", function() {
    var yousure = confirm("Are you sure you want to clear your listen queue?");
    if (yousure) {
      var current = getNowPlayingData();
      if (queueItemIsPlaying(current)) {
        setListenQueue([current]);
        displayListenQueueData([current]);
      }
      else {
        clearListenQueue();
        displayEmptyQueue(LISTENQUEUE);

      }
    }
  });
}


// Listen Queue Display
function setScrollOnSpawn() {
  $("#queueModal").on('shown.bs.modal', function() {
    scrollToCurrentQueueItem();
  });
}

function scrollToCurrentQueueItem() {
  if ($(".queue-row.current").length > 0) {
    $(".js-up-next-section").scrollTo($(".queue-row.current"), 250, {offset:-100});
  }
}

function addItemToListenQueue(queueItem, location) {
  // hide empty state
  $(`${LISTENQUEUE} .js-empty-queue-wrapper`).hide();

  if (location == 'next' || location == 'current') {
    var currentElement = $(".js-up-next .queue-row.current");
    if (currentElement.length > 0) {
      currentElement.after(queueItem);
      if (location == 'current') {
        setItemAsCurrentItem(queueItem);
      }
      return;
    }
  }
  $(".js-up-next").append(queueItem);
  if (location == 'current') {
    setItemAsCurrentItem(queueItem);
  }
}

function displayListenQueueItem(data) {
  var resourceType = getMediaResourceType(data.resource_type);
  var queueItem = $("#blank-queue-item-wrapper .js-queue-support").clone(true,true);

  // Add item specific data
  queueItem.find(".js-queue-title").text(data.title);
  queueItem.find(".js-audio-banner").attr("src", data.image);
  queueItem.attr("data-tracking-id", data.tracking_id);
  queueItem.find(".js-resource-type").text(resourceType);

  // Resource ID
  if (data.display_id) {
    queueItem.find(".js-sku-id").text(`ID: ${data.display_id} • `);
  } else {
    queueItem.find(".js-sku-id").remove();
  }
  // Scripture
  if (data.scripture && data.scripture.length > 0) {
    var scriptures = data.scripture.map(function(val) { return val.reference }).join(", ");
    queueItem.find(".js-scripture-reference-link").text(scriptures);
  } else {
    queueItem.find(".js-scripture-reference-link").remove();
  }
  // Series
  if (data.series_title) {
    queueItem.find(".js-series-link").text(data.series_title);
  } else {
    queueItem.find(".js-series").remove();
  }
  // Date
  if (resourceType == "Bible Passage") {
    queueItem.find(".js-queue-date").removeClass("js-queue-date, queue-date").addClass("js-bible-version bible-version").text("English Standard Version");
  } else if (data.date_display) {
    queueItem.find(".js-queue-date").text(data.date_display);
  }

  // Player Duration
  if (data.duration) {
    var display_time = formatPlayerTime(data.duration);
    queueItem.find(".js-duration").text(display_time);
  } else {
    queueItem.find(".js-duration").css("visibility", "hidden");
  }
  // Player Progress
  var progressWidth = getPlayerProgressPercentage(data.progress, data.duration);
  if (progressWidth) {
    queueItem.find(".js-player-progress-current").css("width", `${progressWidth}%`);
  } else {
    queueItem.find(".js-player-progress").remove();
  }

  // Sorting Arrows
  addDataTrackingId(
    queueItem,
    [
      ".js-move-queue-item-up",
      ".js-move-queue-item-down",
      ".js-queue-checkbox-input"
    ],
    data.tracking_id
  );

  // Media Item Action Items
  var resourceUrl = data.url;
  var libraryAdd = '';
  if (TFL.myFavorites !== false) {
    var favorited = false;
    favorited = $.grep(TFL.myFavorites, function(obj) { return obj.resource_id == data.resource_id && obj.resource_type == data.resource_type && obj.deleted !== true})[0];
    libraryAdd =  (favorited ?
      '<div class="queue-button-wrapper js-add-favorite remove" data-resource_type="' + data.resource_type + '" data-resource_id="' + data.resource_id + '">Remove From My Library</div>' :
      '<div class="queue-button-wrapper js-add-favorite" data-resource_type="' + data.resource_type + '" data-resource_id="' + data.resource_id + '">Add to My Library</div>');
  }

  var contents = '<div style="color:#000;" class="app-queue-popover-menu"><div class="not-current queue-button-wrapper js-queue-remove-item" data-tracking-id="' + data.tracking_id + '">Remove from Queue</div>' +
                  '<div class="js-queue-playnow not-current queue-button-wrapper">Play Now</div>' +
                  libraryAdd +
                  '<div class="queue-button-wrapper"><a href="' + resourceUrl + '">Go to ' + resourceType + '</a></div>' +
                  '<div class="js-close-popover queue-button-wrapper">Cancel</div></div>';

  queueItem.find(".js-add-queue-popover").popover({
    trigger: "focus",
    placement: "left",
    html: true,
    content: contents,
  });

  // Video Disclaimer
  if (data.video) {
    queueItem.find(".js-video-disclaimer").show();
  }

  // Check if Expired
  if (data.expiration) {
    if (checkIfListenQueueExpired(data.expiration)) {
      queueItem.addClass("expired");
      var remove_button = queueItem.find(".js-remove-expired");
      $(remove_button).attr("data-tracking-id", data.tracking_id);
      $(remove_button).on("click", function() {
        var localStorageQueue = getListenQueue();
        var updatedQueue = localStorageQueue.filter(function(val) {
          return val.tracking_id !== data.tracking_id;
        });
        setListenQueue(updatedQueue);
        if (updatedQueue.length > 0) {
          updateListenQueueDOM(updatedQueue);
        } else {
          displayEmptyQueue();
        }
      });
    }
    queueItem.find(".js-expiration").text("Unavailable after " + data.expiration_display).show();
  }

  queueItem.addClass("js-draggable");
  queueItem.attr("draggable", "true");
  return queueItem;
}

function displayListenQueue() {
  var queue = getListenQueue();
  displayListenQueueData(queue);
}

function displayListenQueueData(queue) {
  if (queue && queue.length > 0) {
    var api_url = `/api/favorites/sync/`;
    $.ajax({
      url: api_url,
      method: "GET"
    }).done(function(result) {
      TFL.myFavorites = result.data;
      updateListenQueueDOM(queue);
      setMediaNextButtonStatus(queue);
    })
    .fail(function() {
      TFL.myFavorites = false;
      updateListenQueueDOM(queue);
      setMediaNextButtonStatus(queue);
    });
  } else {
    hideQueuePlayer();
    displayEmptyQueue();
    setMediaNextButtonStatus(queue);
  }
}

function updateListenQueueDOM(queue) {
  if (queue.length < 1) {
    return displayEmptyQueue();
  }

  var newQueue = queue.map(resource => {
    return displayListenQueueItem(resource);
  });

  // append new queue item and remove empty state
  $(`${LISTENQUEUE} .js-empty-queue-wrapper`).hide();
  var cur_height = $(".js-up-next").height();
  if (cur_height > 0) {
    $(".js-up-next").css('min-height', `${cur_height}px`);
  }
  $(LISTENQUEUE).find(".js-queue-support").remove();
  $(LISTENQUEUE).append(newQueue);
  $(".js-up-next").css('min-height', '');


  handleDragEvents();
  disableArrows();
  displayCurrentQueueItem(queue);
  var currentItem = getCurrentQueueItem(queue, true);
  displayQueuePlayerItem(currentItem);
  return newQueue;
}

function displayEmptyQueue() {
  $(LISTENQUEUE).find(".js-queue-support").remove();
  $(`${LISTENQUEUE} .js-empty-queue-wrapper`).show();
  hideQueuePlayer();
}

function displayCurrentQueueItem(queue) {
  var currentItem = getCurrentQueueItem(queue, false);
  if (currentItem) {
    var currentId = currentItem.tracking_id;
    var currentElement = $(LISTENQUEUE).find(`[data-tracking-id='${currentId}']`).first();
    if (currentElement.length > 0) {
      setItemAsCurrentItem(currentElement);
    }
  }
  else {
    hideQueuePlayer();
  }
}

function setItemAsCurrentItem(currentElement) {
    $(".queue-row").removeClass("current");
    currentElement.addClass("current");
}

// Listen Queue Reorder Functionality
function handleDragEvents() {
  var queueItems = Array.prototype.slice.call(document.querySelectorAll(".js-draggable"));
  var dragSrcEl = null;
  var dropTarget = null;
  var startPosition = null;
  var endPosition = null;

  function handleDragStart(e) {
    dragSrcEl = this;
    startPosition = e.screenY;
    dragSrcEl.classList.add("dragging");
  }

  function handleDragOver(e) {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  }

  function handleDrop(e) {
    e.preventDefault();
    var upNextSection = document.querySelector(LISTENQUEUE);
    dropTarget = this;
    endPosition = e.screenY;
    dragSrcEl.classList.remove("dragging");
    if (dragSrcEl != this) {
      if (startPosition > endPosition) {
        // If dragging up, insert dragging element to drop target
        upNextSection.insertBefore(dragSrcEl, this);
      } else {
        // If dragging down, insert dragging element below drop target or at the end of list if nextSibling does not exist
        upNextSection.insertBefore(dragSrcEl, this.nextSibling);
      }
    }
  }

  function handleDragEnd(e) {
    e.stopImmediatePropagation();

    var localStorageQueue = getListenQueue();
    var trackingId = e.target.getAttribute("data-tracking-id");
    var previousId = dropTarget.getAttribute("data-tracking-id");
    var currentIndex = findQueueItemIndex(localStorageQueue, trackingId);
    var toIndex = findQueueItemIndex(localStorageQueue, previousId);

    reorderLocalStorageItems(localStorageQueue, currentIndex, toIndex);
    initializeSortArrows();
    disableArrows();
  }

  queueItems.forEach(item => {
    item.addEventListener("dragstart", handleDragStart);
    item.addEventListener("dragover", handleDragOver);
    item.addEventListener("drop", handleDrop);
    item.addEventListener("dragend", handleDragEnd);
  });
}

function initializeDragAndDrop() {
  $("#queueModal").on("shown.bs.modal", function() {
    handleDragEvents();
  });
}

function handleSortArrows(className) {
  $("body").on("click", className, function(ev) {
    ev.stopImmediatePropagation();
    var localStorageQueue = getListenQueue();
    var node = ev.currentTarget;
    var trackingId = $(node).attr("data-tracking-id");
    var currentIndex = findQueueItemIndex(localStorageQueue, trackingId);
    var toIndex;
    if (className === ".js-move-queue-item-up") {
      toIndex = currentIndex - 1;
    } else {
      toIndex = currentIndex + 1;
    }
    reorderLocalStorageItems(localStorageQueue, currentIndex, toIndex);
    updateListenQueueDOM(localStorageQueue);
  });
}

function disableArrows() {
  var arrows = $(LISTENQUEUE).find(".js-sort-arrow-wrapper");

  function disable(val, arrowDirection) {
    return $(val)
      .find(arrowDirection)
      .addClass("disabled");
  }

  function enable(val, arrowDirection) {
    return $(val)
      .find(arrowDirection)
      .removeClass("disabled");
  }

  arrows.each((index, value) => {
    var first = index === 0;
    var last = index === arrows.length - 1;
    if (first && last) {
      disable(value, ".js-move-queue-item-up");
      disable(value, ".js-move-queue-item-down");
    }
    else if (first) {
      disable(value, ".js-move-queue-item-up");
      enable(value, ".js-move-queue-item-down");
    } else if (last) {
      disable(value, ".js-move-queue-item-down");
      enable(value, ".js-move-queue-item-up");
    } else {
      enable(value, ".js-move-queue-item-up");
      enable(value, ".js-move-queue-item-down");
    }
  });
}

function initializeSortArrows() {
  handleSortArrows(".js-move-queue-item-up");
  handleSortArrows(".js-move-queue-item-down");
}

// Watchers
function setQueueProgressStatus(sync_data) {
  var resource_type = getResourceTypeFrontendName(sync_data["resource_type"]);
  var resource_id = sync_data["resource_id"];
  var listenQueue = getListenQueue();
  var update = false;

  listenQueue.forEach(item => {
    if (
      item.resource_type == resource_type &&
      item.resource_id == resource_id
    ) {
      update = true;
      item.progress = sync_data["seconds"];
    }
  });
  // Update the Storage Queue if We changed any progress
  if (update) {
    setListenQueue(listenQueue);
  }
}

function syncQueueAcrossTabs() {
  window.addEventListener("storage", function(ev) {
    if (ev.key === "listenQueue") {
      var newQueueItem = JSON.parse(ev.newValue);
      displayListenQueueData(newQueueItem);
    }
  });
}


// Listen Queue API
function listenQueueItemAPIResponse(resource_id, resource_type, video = false) {
  var api_url = `/api/listenqueue/${resource_type}/${resource_id}/json/`;
  if (video) {
    api_url += "?video=true";
  }

  return $.ajax({
    url: api_url,
    method: "GET"
  })
    .done(function(result) {
      return result;
    })
    .fail(function() {
      return false;
    });
}

// ----------------------------------------------------  End Listening Queue

// ----------------------------------------------------  Sliders

function createSliders() {
  var instances;
  var sliders;

  instances = [];
  sliders = $(".slide-lateral");
  sliders.each(function(inIndex) {
    var root;
    var obj;

    root = sliders.eq(inIndex);
    obj = {
      $: {
        root: root,
        slider: root.find(".slide-lateral_slider"),
        prev: root.find(".slide-lateral_step--prev"),
        next: root.find(".slide-lateral_step--next"),
        first: root.find(".slide-lateral_tile").eq(0)
      },
      checkLimits: function() {
        var slider;
        var leftEdge, rightEdge;

        slider = obj.$.slider.get(0);
        leftEdge = slider.scrollLeft;
        rightEdge = leftEdge + slider.clientWidth;
        if (leftEdge <= 5) {
          obj.$.root.addClass("limit--min");
        } else {
          obj.$.root.removeClass("limit--min");
        }
        if (rightEdge >= slider.scrollWidth - 5) {
          obj.$.root.addClass("limit--max");
        } else {
          obj.$.root.removeClass("limit--max");
        }
      },
      move: function(inForward) {
        obj.$.slider.stop(true, false);
        obj.$.slider.animate(
          {
            scrollLeft:
              (inForward ? "+=" : "-=") +
              (obj.$.slider.width() - obj.$.first.width())
          },
          200
        );
      },
      moveForward: function() {
        obj.move(true);
      },
      moveBackward: function() {
        obj.move(false);
      },
      bind: function() {
        if (obj.$.root.data("slide-lateral-bound")) {
          return false;
        }
        obj.$.root.data("slide-lateral-bound", true);
        obj.$.root.mouseenter(obj.checkLimits);
        obj.$.slider.scroll(obj.checkLimits);
        obj.$.prev.click(obj.moveBackward);
        obj.$.next.click(obj.moveForward);
      }
    };
    obj.checkLimits();
    obj.bind();
    instances.push(obj);
  });

  return function() {
    for (var i = 0; i < instances.length; i++) {
      instances[i].checkLimits();
    }
  };
}

// ----------------------------------------------------  End Sliders

// -------------------------------------------- Hubspot Email Subscribe Form
function createHubSpotForm() {
  if ($("body").hasClass("index")) {
    window.hbspt.forms.create({
      portalId: "331596",
      formId: "252993c5-9cf8-425f-9dd9-3eb546a17f2a",
      css: "",
      target: "#dailySignUp .form",
      onFormSubmitted: function() {
        var daily = $(".container--daily");
        $(".daily-signup-message").remove();
        daily.find(".create-account-message").addClass("daily");
        daily
          .find(".submitted-message p")
          .replaceWith($(".create-account-message.daily"));
        $(".create-account-message.daily").show();
      }
    });
  }
  else if ($("body").is(".daily,.devotional")) {
    window.hbspt.forms.create({
      portalId: '331596',
      formId: 'f958bdfd-4c53-41d8-bd87-8b320f14914e',
      css: '',
      target: '.js-content-email .form',
    });
  }
}
// -------------------------------------------- End Hubspot Email Subscribe Form
